import axios from "axios";
import _ from "lodash";

import { buildURL } from "@/tools";
import { EQUIPMENTS_URL } from "@/config/urls";

import { EquipmentInfos, EquipmentInfosDTO } from "@/domain";
import { EquipmentFilterDTO, IEquipmentService } from "@/services";

export class EquipmentService implements IEquipmentService {
    async getMany(filter: EquipmentFilterDTO): Promise<EquipmentInfos[]> {
        const response = await axios.get(buildURL(EQUIPMENTS_URL, filter));
        const dtos: EquipmentInfosDTO[] = response.data;
        return _.map(dtos, d => new EquipmentInfos(d));
    }
}