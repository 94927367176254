import _ from "lodash";
import { parseISO } from "date-fns";
import { HeaderInfos, HeaderInfosDTO, TableOrigin } from "prometheus-synced-ui";

export class MeasureTableInfos {
    public id: string;
    public zoneId: string;
    public visitId: string;
    public version: number;
    public date: Date | null;
    public creationDate: Date;
    public lastUpdateDate: Date;
    public lastCreatorId: string;
    public creatorId: string;
    public origin: TableOrigin;
    public lineHeaders: HeaderInfos[];
    public columnHeaders: HeaderInfos[];
    public measureIds: string[];

    constructor(parameters: MeasureTableInfosDTO) {
        this.id = parameters.id;
        this.zoneId = parameters.zoneId;
        this.visitId = parameters.visitId;
        this.version = parameters.version;
        this.origin = parameters.origin;
        this.date = parameters.date != null && parseISO(parameters.date) || null;
        this.creationDate = parseISO(parameters.creationDate);
        this.lastUpdateDate = parseISO(parameters.lastUpdateDate);
        this.creatorId = parameters.creatorId;
        this.lastCreatorId = parameters.lastCreatorId;
        this.lineHeaders = _.map(parameters.lineHeaders, l => new HeaderInfos(l));
        this.columnHeaders = _.map(parameters.columnHeaders, l => new HeaderInfos(l));
        this.measureIds = parameters.measureIds;
    }
}

export interface MeasureTableInfosDTO {
    id: string;
    zoneId: string;
    visitId: string;
    version: number;
    date: string | null;
    creationDate: string;
    lastUpdateDate: string;
    creatorId: string;
    lastCreatorId: string;
    origin: TableOrigin;
    lineHeaders: HeaderInfosDTO[];
    columnHeaders: HeaderInfosDTO[];
    measureIds: string[];
}