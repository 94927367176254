import { ServerType } from "prometheus-synced-ui";
import { FunctionBasedFeatureFlag } from "vue-feature-flags";

export const CLOUD_FEATURE: FunctionBasedFeatureFlag = new FunctionBasedFeatureFlag({
    visibility: (serverType: ServerType) => {
        return serverType == ServerType.cloud;
    }
});

export const LITE_FEATURE: FunctionBasedFeatureFlag = new FunctionBasedFeatureFlag({
    visibility: (serverType: ServerType) => {
        return serverType == ServerType.chmo;
    }
});

