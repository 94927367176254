import { CalibrationBlockCategory, MaterialShadeInfos, MaterialShadeInfosDTO, StepCalibrationBlockItem, V1CalibrationBlockItem, V2CalibrationBlockItem } from "@/domain";

export class CalibrationBlockItem {
    public id: string;
    public blockCategory: CalibrationBlockCategory;
    public serialNumber: string;
    public minDimension: number | null;
    public maxDimension: number | null;
    public dimensionValue: number | null;
    public materialShade: MaterialShadeInfos;
    public celerity: number;
    public pvNumber: string;
    public disabled: boolean;

    constructor(parameters: CalibrationBlockItemDTO) {
        this.id = parameters.id;
        this.blockCategory = parameters.blockCategory;
        this.serialNumber = parameters.serialNumber;
        this.minDimension = parameters.minDimension;
        this.maxDimension = parameters.maxDimension;
        this.dimensionValue = parameters.dimensionValue;
        this.materialShade = new MaterialShadeInfos(parameters.materialShade);
        this.celerity = parameters.celerity;
        this.pvNumber = parameters.pvNumber;
        this.disabled = parameters.disabled

    }

    static fromV1(v1Block: V1CalibrationBlockItem): CalibrationBlockItem {
        return new CalibrationBlockItem({
            id: v1Block.id,
            serialNumber: v1Block.serialNumber,
            blockCategory: CalibrationBlockCategory.v1,
            celerity: v1Block.celerity,
            dimensionValue: null,
            minDimension: v1Block.minDimension,
            maxDimension: v1Block.maxDimension,
            materialShade: v1Block.materialShade,
            pvNumber: v1Block.pvNumber,
            disabled: v1Block.disabled
        })
    }

    static fromV2(v2Block: V2CalibrationBlockItem): CalibrationBlockItem {
        return new CalibrationBlockItem({
            id: v2Block.id,
            serialNumber: v2Block.serialNumber,
            blockCategory: CalibrationBlockCategory.v2,
            celerity: v2Block.celerity,
            dimensionValue: v2Block.dimensionValue,
            minDimension: null,
            maxDimension: null,
            materialShade: v2Block.materialShade,
            pvNumber: v2Block.pvNumber,
            disabled: v2Block.disabled
        })
    }

    static fromStep(stepBlock: StepCalibrationBlockItem): CalibrationBlockItem {
        return new CalibrationBlockItem({
            id: stepBlock.id,
            serialNumber: stepBlock.serialNumber,
            blockCategory: CalibrationBlockCategory.step,
            celerity: stepBlock.celerity,
            dimensionValue: null,
            minDimension: stepBlock.minDimension,
            maxDimension: stepBlock.maxDimension,
            materialShade: stepBlock.materialShade,
            pvNumber: stepBlock.pvNumber,
            disabled: stepBlock.disabled
        })
    }
}

export interface CalibrationBlockItemDTO {
    id: string;
    blockCategory: CalibrationBlockCategory;
    serialNumber: string;
    minDimension: number | null;
    maxDimension: number | null;
    dimensionValue: number | null;
    materialShade: MaterialShadeInfosDTO;
    celerity: number;
    pvNumber: string;
    disabled: boolean;
}