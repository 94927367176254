import _ from "lodash";
import axios from "axios";

import { buildURL } from "@/tools";
import { HYGROMETERS_URL, HYGROMETER_URL } from "@/config/urls";

import { HygrometerDetails, HygrometerDetailsDTO, HygrometerItem, HygrometerItemDTO } from "@/domain";
import { CreateHygrometerDTO, HygrometerFilterDTO, IHygrometerService, UpdateHygrometerDTO } from "@/services";

export class HygrometerService implements IHygrometerService {
    async getMany(filter: HygrometerFilterDTO): Promise<HygrometerItem[]> {
        const response = await axios.get(buildURL(HYGROMETERS_URL, filter));
        const dtos: HygrometerItemDTO[] = response.data;
        return _.map(dtos, d => new HygrometerItem(d));
    }

    async create(payload: CreateHygrometerDTO): Promise<HygrometerDetails> {
        const response = await axios.post(HYGROMETERS_URL, payload);
        const dto: HygrometerDetailsDTO = response.data;
        return new HygrometerDetails(dto);
    }

    async update(id: string, payload: UpdateHygrometerDTO): Promise<HygrometerDetails> {
        const response = await axios.post(HYGROMETER_URL(id), payload);
        const dto: HygrometerDetailsDTO = response.data;
        return new HygrometerDetails(dto);
    }
}