export class UncertaintyStep {
    public uncertaintyValue: number;
    public lowLimit: number;
    public highLimit: number | null;

    constructor(parameters: UncertaintyStepDTO) {
        this.uncertaintyValue = parameters.uncertaintyValue;
        this.lowLimit = parameters.lowLimit;
        this.highLimit = parameters.highLimit;
    }
}

export interface UncertaintyStepDTO {
    uncertaintyValue: number;
    lowLimit: number;
    highLimit: number | null;
}