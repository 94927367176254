import axios from "axios";
import _ from "lodash";

import { V2_CALIBRATION_BLOCKS_URL, V2_CALIBRATION_BLOCK_URL } from "@/config/urls";
import { buildURL } from "@/tools";

import { V2CalibrationBlockDetails, V2CalibrationBlockDetailsDTO, V2CalibrationBlockItem, V2CalibrationBlockItemDTO } from "@/domain";
import { CreateV2CalibrationBlockDTO, IV2CalibrationBlockService, UpdateV2CalibrationBlockDTO, V2CalibrationBlockFilterDTO } from "@/services/interfaces";

export class V2CalibrationBlockService implements IV2CalibrationBlockService {
    async getMany(filter: V2CalibrationBlockFilterDTO): Promise<V2CalibrationBlockItem[]> {
        const response = await axios.get(buildURL(V2_CALIBRATION_BLOCKS_URL, filter));
        const dtos: V2CalibrationBlockItemDTO[] = response.data;
        return _.map(dtos, d => new V2CalibrationBlockItem(d));
    }

    async create(payload: CreateV2CalibrationBlockDTO): Promise<V2CalibrationBlockDetails> {
        const response = await axios.post(V2_CALIBRATION_BLOCKS_URL, payload);
        const dto: V2CalibrationBlockDetailsDTO = response.data;
        return new V2CalibrationBlockDetails(dto);
    }

    async update(id: string, payload: UpdateV2CalibrationBlockDTO): Promise<V2CalibrationBlockDetails> {
        const response = await axios.post(V2_CALIBRATION_BLOCK_URL(id), payload);
        const dto: V2CalibrationBlockDetailsDTO = response.data;
        return new V2CalibrationBlockDetails(dto);
    }
}