import _ from "lodash";
import { CalibrationCriteriaInfos, CalibrationCriteriaInfosDTO, ProceadureDetails, RoughnessCriteria, RoughnessCriteriaDTO, TemperatureCriteria, TemperatureCriteriaDTO, ThicknessCriteria, ThicknessCriteriaDTO, ThresholdCriteria, ThresholdCriteriaDTO, UncertaintyCriteria, UncertaintyCriteriaDTO, WeldingCriteria, WeldingCriteriaDTO } from "@/domain";

export class ProceadureInfos {
    public id: string;
    public name: string;
    public temperatureCriteria: TemperatureCriteria | null;
    public thicknessCriteria: ThicknessCriteria | null;
    public roughnessCriteria: RoughnessCriteria | null;
    public weldingCriteria: WeldingCriteria | null;
    public calibrationCriteria: CalibrationCriteriaInfos | null;
    public uncertaintyCriteria: UncertaintyCriteria | null;
    public thresholdCriteria: ThresholdCriteria | null;
    public pdfFileIds: string[];
    public disabled: boolean;

    constructor(parameters: ProceadureInfosDTO) {
        this.id = parameters.id;
        this.name = parameters.name;
        this.temperatureCriteria = parameters.temperatureCriteria && new TemperatureCriteria(parameters.temperatureCriteria) || null;
        this.thicknessCriteria = parameters.thicknessCriteria && new ThicknessCriteria(parameters.thicknessCriteria) || null;
        this.roughnessCriteria = parameters.roughnessCriteria && new RoughnessCriteria(parameters.roughnessCriteria) || null;
        this.weldingCriteria = parameters.weldingCriteria && new WeldingCriteria(parameters.weldingCriteria) || null;
        this.calibrationCriteria = parameters.calibrationCriteria && new CalibrationCriteriaInfos(parameters.calibrationCriteria) || null;
        this.uncertaintyCriteria = parameters.uncertaintyCriteria && new UncertaintyCriteria(parameters.uncertaintyCriteria) || null;
        this.thresholdCriteria = parameters.thresholdCriteria && new ThresholdCriteria(parameters.thresholdCriteria) || null;
        this.pdfFileIds = parameters.pdfFileIds;
        this.disabled = parameters.disabled;
    }

    static fromDetails(proceadure: ProceadureDetails): ProceadureInfos {
        return new ProceadureInfos({
            id: proceadure.id,
            name: proceadure.name,
            temperatureCriteria: proceadure.temperatureCriteria,
            thicknessCriteria: proceadure.thicknessCriteria,
            roughnessCriteria: proceadure.roughnessCriteria,
            weldingCriteria: proceadure.weldingCriteria,
            calibrationCriteria: proceadure.calibrationCriteria && new CalibrationCriteriaInfos({
                materialShadeId: proceadure.calibrationCriteria.materialShade.id,
                celerityRange: proceadure.calibrationCriteria.celerityRange,
                celerityValue: proceadure.calibrationCriteria.celerityValue,
                minimumCelerity: proceadure.calibrationCriteria.minimumCelerity,
                maximumCelerity: proceadure.calibrationCriteria.maximumCelerity
            }) || null,
            uncertaintyCriteria: proceadure.uncertaintyCriteria,
            thresholdCriteria: proceadure.thresholdCriteria,
            pdfFileIds: _.map(proceadure.pdfFiles, f => f.id),
            disabled: proceadure.disabled
        })
    }
}

export interface ProceadureInfosDTO {
    id: string;
    name: string;
    temperatureCriteria: TemperatureCriteriaDTO | null;
    thicknessCriteria: ThicknessCriteriaDTO | null;
    roughnessCriteria: RoughnessCriteriaDTO | null;
    weldingCriteria: WeldingCriteriaDTO | null;
    calibrationCriteria: CalibrationCriteriaInfosDTO | null;
    uncertaintyCriteria: UncertaintyCriteriaDTO | null;
    thresholdCriteria: ThresholdCriteriaDTO | null;
    pdfFileIds: string[];
    disabled: boolean;
}