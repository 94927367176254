import axios from "axios";
import _ from "lodash";

import { LUXMETERS_URL, LUXMETER_URL } from "@/config/urls";
import { buildURL } from "@/tools";

import { LuxmeterDetails, LuxmeterDetailsDTO, LuxmeterItem, LuxmeterItemDTO } from "@/domain";
import { CreateLuxmeterDTO, ILuxmeterService, LuxmeterFilterDTO, UpdateLuxmeterDTO } from "@/services";

export class LuxmeterService implements ILuxmeterService {
    async getMany(filter: LuxmeterFilterDTO): Promise<LuxmeterItem[]> {
        const response = await axios.get(buildURL(LUXMETERS_URL, filter));
        const dtos: LuxmeterItemDTO[] = response.data;
        return _.map(dtos, d => new LuxmeterItem(d));
    }

    async create(payload: CreateLuxmeterDTO): Promise<LuxmeterDetails> {
        const response = await axios.post(LUXMETERS_URL, payload);
        const dto: LuxmeterDetailsDTO = response.data;
        return new LuxmeterDetails(dto);
    }

    async update(id: string, payload: UpdateLuxmeterDTO): Promise<LuxmeterDetails> {
        const response = await axios.post(LUXMETER_URL(id), payload);
        const dto: LuxmeterDetailsDTO = response.data;
        return new LuxmeterDetails(dto);
    }
}