export class WeldingCriteria {
    public deltaPercentage: number;
    public constantDeltas: number[];

    constructor(parameters: WeldingCriteriaDTO) {
        this.deltaPercentage = parameters.deltaPercentage;
        this.constantDeltas = parameters.constantDeltas;
    }
}

export interface WeldingCriteriaDTO {
    deltaPercentage: number;
    constantDeltas: number[];
}