import _ from "lodash";
import { HydraulicSystemInfos, HydraulicSystemInfosDTO } from "@/domain";

export class HydraulicSystemGroupDetails {
    public id: string;
    public name: string;
    public monogram: string;
    public systems: HydraulicSystemInfos[];
    public disabled: boolean;

    constructor(parameters: HydraulicSystemGroupDetailsDTO) {
        this.id = parameters.id;
        this.name = parameters.name;
        this.monogram = parameters.monogram;
        this.systems = _.map(parameters.systems, s => new HydraulicSystemInfos(s));
        this.disabled = parameters.disabled;
    }
}

export interface HydraulicSystemGroupDetailsDTO {
    id: string;
    name: string;
    monogram: string;
    systems: HydraulicSystemInfosDTO[];
    disabled: boolean;
}