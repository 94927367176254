export class EnvironmentMeasureInfos {
    public blockTemperature: number | null;
    public ambientTemperature: number | null;
    public celerityLag: number | null;
    public runCelerity: number | null;
    public alternation: number | null;

    constructor(parameters: EnvironmentMeasureInfosDTO) {
        this.blockTemperature = parameters.blockTemperature;
        this.ambientTemperature = parameters.ambientTemperature;
        this.celerityLag = parameters.celerityLag;
        this.runCelerity = parameters.runCelerity;
        this.alternation = parameters.alternation;
    }
}

export interface EnvironmentMeasureInfosDTO {
    blockTemperature: number | null;
    ambientTemperature: number | null;
    celerityLag: number | null;
    runCelerity: number | null;
    alternation: number | null;
}