import { ImportWarningType } from "@/domain";
import { HistoricType } from "prometheus-synced-ui";

export class ImportWarning {
    public historicType: HistoricType;
    public type: ImportWarningType;
    public message: string;

    constructor(parameters: ImportWarningDTO) {
        this.historicType = parameters.historicType;
        this.type = parameters.type;
        this.message = parameters.message;
    }
}

export interface ImportWarningDTO {
    historicType: HistoricType;
    type: ImportWarningType;
    message: string;
}