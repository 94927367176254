import _ from "lodash";
import axios from "axios";

import { buildURL } from "@/tools";
import { HYDRAULIC_SYSTEM_GROUPS_URL, HYDRAULIC_SYSTEM_GROUP_URL } from "@/config/urls";

import { HydraulicSystemGroupDetails, HydraulicSystemGroupDetailsDTO, HydraulicSystemGroupInfos, HydraulicSystemGroupInfosDTO } from "@/domain";
import { IHydraulicSystemGroupService, HydraulicSystemGroupFilterDTO, CreateHydraulicSystemGroupDTO, UpdateHydraulicSystemGroupDTO } from "@/services";

export class HydraulicSystemGroupService implements IHydraulicSystemGroupService {
    async getMany(filter: HydraulicSystemGroupFilterDTO): Promise<HydraulicSystemGroupInfos[]> {
        const response = await axios.get(buildURL(HYDRAULIC_SYSTEM_GROUPS_URL, filter));
        const dtos: HydraulicSystemGroupInfosDTO[] = response.data;
        return _.map(dtos, d => new HydraulicSystemGroupInfos(d));
    }

    async create(payload: CreateHydraulicSystemGroupDTO): Promise<HydraulicSystemGroupDetails> {
        const response = await axios.post(HYDRAULIC_SYSTEM_GROUPS_URL, payload);
        const dto: HydraulicSystemGroupDetailsDTO = response.data;
        return new HydraulicSystemGroupDetails(dto);
    }

    async update(id: string, payload: UpdateHydraulicSystemGroupDTO): Promise<HydraulicSystemGroupDetails> {
        const response = await axios.post(HYDRAULIC_SYSTEM_GROUP_URL(id), payload);
        const dto: HydraulicSystemGroupDetailsDTO = response.data;
        return new HydraulicSystemGroupDetails(dto);
    }
}