
import { Component, InjectReactive, Vue } from "vue-property-decorator";

import { SERVER_CONFIG } from "@/config/literals";

import { ServerConfig } from "prometheus-synced-ui";

@Component
export default class Logging extends Vue {
  @InjectReactive(SERVER_CONFIG)
  serverConfig!: ServerConfig;

  get serverName(): string {
    return this.serverConfig.name;
  }
}
