import "reflect-metadata";

import Vue from 'vue'
import App from './App.vue'
import router from './router'
import vuetify from './plugins/vuetify'
import { PROVIDER } from './config/literals';
import { container } from "./injector";
import 'roboto-fontface/css/roboto/roboto-fontface.css';
import '@mdi/font/css/materialdesignicons.css';
import "@/assets/style.css";

Vue.config.productionTip = false

new Vue({
  router,
  vuetify,
  provide: {
    [PROVIDER]: container
  },
  render: h => h(App)
}).$mount('#app')
