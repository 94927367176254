import axios from "axios";
import _ from "lodash";

import { V1_CALIBRATION_BLOCKS_URL, V1_CALIBRATION_BLOCK_URL } from "@/config/urls";
import { buildURL } from "@/tools";

import { V1CalibrationBlockDetails, V1CalibrationBlockDetailsDTO, V1CalibrationBlockItem, V1CalibrationBlockItemDTO } from "@/domain";
import { CreateV1CalibrationBlockDTO, IV1CalibrationBlockService, UpdateV1CalibrationBlockDTO, V1CalibrationBlockFilterDTO } from "@/services/interfaces";

export class V1CalibrationBlockService implements IV1CalibrationBlockService {
    async getMany(filter: V1CalibrationBlockFilterDTO): Promise<V1CalibrationBlockItem[]> {
        const response = await axios.get(buildURL(V1_CALIBRATION_BLOCKS_URL, filter));
        const dtos: V1CalibrationBlockItemDTO[] = response.data;
        return _.map(dtos, d => new V1CalibrationBlockItem(d));
    }

    async create(payload: CreateV1CalibrationBlockDTO): Promise<V1CalibrationBlockDetails> {
        const response = await axios.post(V1_CALIBRATION_BLOCKS_URL, payload);
        const dto: V1CalibrationBlockDetailsDTO = response.data;
        return new V1CalibrationBlockDetails(dto);
    }

    async update(id: string, payload: UpdateV1CalibrationBlockDTO): Promise<V1CalibrationBlockDetails> {
        const response = await axios.post(V1_CALIBRATION_BLOCK_URL(id), payload);
        const dto: V1CalibrationBlockDetailsDTO = response.data;
        return new V1CalibrationBlockDetails(dto);
    }
}