import { parseISO } from "date-fns";
import {
    EquipmentInfos, EquipmentInfosDTO, ExamReportInfos, ExamReportInfosDTO, HydraulicSystemInfos, HydraulicSystemInfosDTO,
    MeasuringRunStatus, OperationalFileInfos, OperationalFileInfosDTO
} from "@/domain";

import {
    VisitInfos, VisitInfosDTO, PowerPlantInfos, UserInfos, UserInfosDTO,
    PowerPlantInfosDTO, NuclearUnitInfos, NuclearUnitInfosDTO, ZoneInfos, ZoneInfosDTO
} from "prometheus-synced-ui";

export class MeasuringRunResume {
    public id: string;
    public operationalFile: OperationalFileInfos;
    public examReport: ExamReportInfos;
    public currentVisit: VisitInfos;
    public powerPlant: PowerPlantInfos;
    public nuclearUnit: NuclearUnitInfos;
    public system: HydraulicSystemInfos;
    public equipment: EquipmentInfos;
    public zone: ZoneInfos;
    public creator: UserInfos;
    public controller: UserInfos | null;
    public version: number;
    public status: MeasuringRunStatus;
    public creationDate: Date;
    public isDeletable: boolean;
    public isAssignable: boolean;

    constructor(parameters: MeasuringRunResumeDTO) {
        this.id = parameters.id;
        this.operationalFile = new OperationalFileInfos(parameters.operationalFile);
        this.examReport = new ExamReportInfos(parameters.examReport);
        this.currentVisit = new VisitInfos(parameters.currentVisit);
        this.powerPlant = new PowerPlantInfos(parameters.powerPlant);
        this.nuclearUnit = new NuclearUnitInfos(parameters.nuclearUnit);
        this.system = new HydraulicSystemInfos(parameters.system);
        this.equipment = new EquipmentInfos(parameters.equipment);
        this.zone = new ZoneInfos(parameters.zone);
        this.creator = new UserInfos(parameters.creator);
        this.controller = parameters.controller != null ? new UserInfos(parameters.controller) : null;
        this.version = parameters.version;
        this.status = parameters.status;
        this.creationDate = parseISO(parameters.creationDate)
        this.isDeletable = parameters.isDeletable;
        this.isAssignable = parameters.isAssignable;
    }
}

export interface MeasuringRunResumeDTO {
    id: string;
    operationalFile: OperationalFileInfosDTO;
    examReport: ExamReportInfosDTO;
    currentVisit: VisitInfosDTO;
    powerPlant: PowerPlantInfosDTO;
    nuclearUnit: NuclearUnitInfosDTO;
    system: HydraulicSystemInfosDTO;
    equipment: EquipmentInfosDTO;
    zone: ZoneInfosDTO;
    creator: UserInfosDTO;
    controller: UserInfosDTO | null;
    version: number;
    status: MeasuringRunStatus;
    creationDate: string;
    isDeletable: boolean;
    isAssignable: boolean;
}