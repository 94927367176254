export class EquipmentInfos {
    public id: string;
    public hydraulicSystemId: string;
    public ngram: string;
    public number: string;
    public disabled: boolean;

    constructor(parameters: EquipmentInfosDTO) {
        this.id = parameters.id;
        this.hydraulicSystemId = parameters.hydraulicSystemId;
        this.ngram = parameters.ngram;
        this.number = parameters.number;
        this.disabled = parameters.disabled;
    }
}

export interface EquipmentInfosDTO {
    id: string;
    hydraulicSystemId: string;
    ngram: string;
    number: string;
    disabled: boolean;
}