export class TemperatureCriteria {
    public minimum: number;
    public maximum: number;
    public delta: number | null;

    constructor(parameters: TemperatureCriteriaDTO) {
        this.minimum = parameters.minimum;
        this.maximum = parameters.maximum;
        this.delta = parameters.delta;
    }
}

export interface TemperatureCriteriaDTO {
    minimum: number;
    maximum: number;
    delta: number | null;
}