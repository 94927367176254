import axios from "axios";
import _ from "lodash";

import { PENETRANTS_URL, PENETRANT_URL } from "@/config/urls";
import { buildURL } from "@/tools";

import { PenetrantDetailsDTO, PenetrantDetails, PenetrantItem, PenetrantItemDTO } from "@/domain";
import { CreatePenetrantDTO, IPenetrantService, PenetrantFilterDTO, UpdatePenetrantDTO } from "@/services";

export class PenetrantService implements IPenetrantService {
    async getMany(filter: PenetrantFilterDTO): Promise<PenetrantItem[]> {
        const response = await axios.get(buildURL(PENETRANTS_URL, filter));
        const dtos: PenetrantItemDTO[] = response.data;
        return _.map(dtos, d => new PenetrantItem(d));
    }

    async create(payload: CreatePenetrantDTO): Promise<PenetrantDetails> {
        const response = await axios.post(PENETRANTS_URL, payload);
        const dto: PenetrantDetailsDTO = response.data;
        return new PenetrantDetails(dto);
    }

    async update(id: string, payload: UpdatePenetrantDTO): Promise<PenetrantDetails> {
        const response = await axios.post(PENETRANT_URL(id), payload);
        const dto: PenetrantDetailsDTO = response.data;
        return new PenetrantDetails(dto);
    }
}