import { parseISO } from "date-fns";

export class PdfFileInfos {
    public id: string;
    public proceadureId: string;
    public creationDate: Date;
    public creatorId: string;
    public name: string;
    public content: ArrayBuffer;

    constructor(parameters: PdfFileInfosDTO) {
        this.id = parameters.id;
        this.proceadureId = parameters.proceadureId;
        this.creationDate = parseISO(parameters.creationDate);
        this.creatorId = parameters.creatorId;
        this.name = parameters.name;
        this.content = parameters.content;
    }
}

export interface PdfFileInfosDTO {
    id: string;
    proceadureId: string;
    creationDate: string;
    creatorId: string;
    name: string;
    content: ArrayBuffer;
}