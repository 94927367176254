export class ThresholdStep {
    public lowLimit: number;
    public highLimit: number;
    public thresholdValue: number;

    constructor(parameters: ThresholdStepDTO) {
        this.lowLimit = parameters.lowLimit;
        this.highLimit = parameters.highLimit;
        this.thresholdValue = parameters.thresholdValue;
    }
}

export interface ThresholdStepDTO {
    lowLimit: number;
    highLimit: number;
    thresholdValue: number;
}