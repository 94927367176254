import { parseISO } from "date-fns";
import { UserInfos, UserInfosDTO } from "prometheus-synced-ui";

export class TableHistory {
    public user: UserInfos;
    public date: Date;

    constructor(parameters: TableHistoryDTO) {
        this.user = new UserInfos(parameters.user);
        this.date = parseISO(parameters.date);
    }
}

export interface TableHistoryDTO {
    user: UserInfosDTO;
    date: string;
}