import { SentenceDetails } from "@/domain";

export class SentenceInfos {
    public id: string;
    public number: number | null;
    public method: string;
    public target: string;
    public sentenceValue: string;

    constructor(parameters: SentenceInfosDTO) {
        this.id = parameters.id;
        this.number = parameters.number;
        this.method = parameters.method;
        this.target = parameters.target;
        this.sentenceValue = parameters.sentenceValue;
    }

    static fromDetails(sentence: SentenceDetails): SentenceInfos {
        return new SentenceInfos({
            id: sentence.id,
            method: sentence.method,
            number: sentence.number,
            sentenceValue: sentence.sentenceValue,
            target: sentence.target
        });
    }
}

export interface SentenceInfosDTO {
    id: string;
    number: number | null;
    method: string;
    target: string;
    sentenceValue: string;
}