import _ from "lodash";
import { GenerationInfos, GenerationInfosDTO, HydraulicSystemTree, HydraulicSystemTreeDTO } from "@/domain";
import { toUnitNumber } from "@/tools";

export class NuclearUnitTree {
    public id: string;
    public number: number;
    public generation: GenerationInfos;
    public systems: HydraulicSystemTree[];
    public disabled: boolean;

    constructor(parameters: NuclearUnitTreeDTO) {
        this.id = parameters.id;
        this.number = parameters.number;
        this.generation = new GenerationInfos(parameters.generation);
        this.systems = _.map(parameters.systems, s => new HydraulicSystemTree(s));
        this.disabled = parameters.disabled;
    }

    public get numberLabel(): string {
        return toUnitNumber(this.number);        
    }
}
export interface NuclearUnitTreeDTO {
    id: string;
    number: number;
    generation: GenerationInfosDTO;
    systems: HydraulicSystemTreeDTO[];
    disabled: boolean;
}