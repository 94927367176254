export class OperationalFileInfos{
    public id: string;
    public number: string;

    constructor(parameters: OperationalFileInfosDTO){
        this.id = parameters.id;
        this.number = parameters.number;
    }
}

export interface OperationalFileInfosDTO{
    id: string;
    number: string;
}