export function toNumber(value: string): number {
    return isNaN(parseFloat(value)) ? 0 : parseFloat(value);
}

export function toNullableNumber(value: string): number | null {
    return isNaN(parseFloat(value)) ? null : parseFloat(value);
}

export function toUnitNumber(value: number): string {
    return "T" + value;
}
