import { EquipmentBrandInfos, EquipmentBrandInfosDTO, PenetrantDetails } from "@/domain";

export class PenetrantItem {
    public id: string;
    public serialNumber: string;
    public equipmentType: string;
    public equipmentBrand: EquipmentBrandInfos;
    public pvNumber: string;
    public isFluorescent: boolean;
    public disabled: boolean;

    constructor(parameters: PenetrantItemDTO) {
        this.id = parameters.id;
        this.serialNumber = parameters.serialNumber;
        this.equipmentType = parameters.equipmentType;
        this.equipmentBrand = new EquipmentBrandInfos(parameters.equipmentBrand);
        this.pvNumber = parameters.pvNumber;
        this.isFluorescent = parameters.isFluorescent;
        this.disabled = parameters.disabled;
    }

    static fromDetails(penetrant: PenetrantDetails): PenetrantItem {
        return new PenetrantItem({
            id: penetrant.id,
            equipmentBrand: penetrant.equipmentBrand,
            equipmentType: penetrant.equipmentType,
            pvNumber: penetrant.pvNumber,
            serialNumber: penetrant.serialNumber,
            isFluorescent: penetrant.isFluorescent,
            disabled: penetrant.disabled
        })
    }
}

export interface PenetrantItemDTO {
    id: string;
    serialNumber: string;
    equipmentType: string;
    equipmentBrand: EquipmentBrandInfosDTO;
    pvNumber: string;
    isFluorescent: boolean;
    disabled: boolean;
}