import { SocietyInfos, SocietyInfosDTO } from "@/domain";
import { lightFormat, parseISO } from "date-fns";

import { UserInfos } from "prometheus-synced-ui";

export class UserDetails {
    public id: string;
    public fullName: string;
    public society: SocietyInfos;
    public badgeNumber: string | null;
    public email: string | null;
    public isSuperAdministrator: boolean;
    public isAdministrator: boolean;
    public canAccessDb: boolean;
    public canAccessExamReport: boolean;
    public canAccessMeasureApp: boolean;
    public leavingDate: Date | null;

    constructor(parameters: UserDetailsDTO) {
        this.id = parameters.id;
        this.fullName = parameters.fullName;
        this.society = new SocietyInfos(parameters.society);
        this.badgeNumber = parameters.badgeNumber;
        this.email = parameters.email;
        this.isSuperAdministrator = parameters.isSuperAdministrator;
        this.isAdministrator = parameters.isAdministrator;
        this.canAccessDb = parameters.canAccessDb;
        this.canAccessExamReport = parameters.canAccessExamReport;
        this.canAccessMeasureApp = parameters.canAccessMeasureApp;
        this.leavingDate = parameters.leavingDate != null && parseISO(parameters.leavingDate) || null;
    }

    public toInfos(): UserInfos {
        return new UserInfos({
            id: this.id,
            fullName: this.fullName,
            badgeNumber: this.badgeNumber,
            email: this.email,
            canAccessDb: this.canAccessDb,
            canAccessExamReport: this.canAccessExamReport,
            canAccessMeasureApp: this.canAccessMeasureApp,
            isSuperAdministrator: this.isSuperAdministrator,
            isAdministrator: this.isAdministrator,
            societyId: this.society.id,
            leavingDate: this.leavingDate && lightFormat(this.leavingDate, "yyyy-MM-dd") || null
        })
    }
}

export interface UserDetailsDTO {
    id: string;
    fullName: string;
    society: SocietyInfosDTO;
    badgeNumber: string | null;
    email: string | null;
    isSuperAdministrator: boolean;
    isAdministrator: boolean;
    canAccessDb: boolean;
    canAccessExamReport: boolean;
    canAccessMeasureApp: boolean;
    leavingDate: string | null;
}