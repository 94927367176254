
import { Component, Vue } from "vue-property-decorator";

import { AZURE_AD_LOGIN_URL } from "@/config/urls";

import Logging from "@/views/Logging.vue";

@Component({
  components: {
    Logging,
  },
})
export default class LoggingAzureAD extends Vue {
  url: string = AZURE_AD_LOGIN_URL;
}
