import axios from "axios";
import _ from "lodash";

import { UT_BOARD_URL, UT_BOARDS_URL } from "@/config/urls";
import { buildURL } from "@/tools";

import { UtBoardDetails, UtBoardDetailsDTO, } from "@/domain";
import { UtBoardItem, UtBoardItemDTO } from "prometheus-synced-ui";

import { CreateUtBoardDTO, UtBoardFilterDTO, IUtBoardService, UpdateUtBoardDTO } from "@/services";

export class UtBoardService implements IUtBoardService {
    async getMany(filter: UtBoardFilterDTO): Promise<UtBoardItem[]> {
        const response = await axios.get(buildURL(UT_BOARDS_URL, filter));
        const dtos: UtBoardItemDTO[] = response.data;
        return _.map(dtos, d => new UtBoardItem(d));
    }

    async create(payload: CreateUtBoardDTO): Promise<UtBoardDetails> {
        const response = await axios.post(UT_BOARDS_URL, payload);
        const dto: UtBoardDetailsDTO = response.data;
        return new UtBoardDetails(dto);
    }

    async update(id: string, payload: UpdateUtBoardDTO): Promise<UtBoardDetails> {
        const response = await axios.post(UT_BOARD_URL(id), payload);
        const dto: UtBoardDetailsDTO = response.data;
        return new UtBoardDetails(dto);
    }
}