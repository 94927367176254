export class ThicknessCriteria {
    public minimum: number;
    public maximum: number;

    constructor(parameters: ThicknessCriteriaDTO) {
        this.minimum = parameters.minimum;
        this.maximum = parameters.maximum;
    }
}

export interface ThicknessCriteriaDTO {
    minimum: number;
    maximum: number;
}