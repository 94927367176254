import { parseISO } from "date-fns";
import {
    EquipmentInfos, EquipmentInfosDTO, HydraulicSystemInfos, HydraulicSystemInfosDTO,
    TableHistory, TableHistoryDTO
} from "@/domain";

import { ZoneInfos, ZoneInfosDTO } from "prometheus-synced-ui";

export class OffCriteriaTableResume {
    public id: string;
    public runVersion: number;
    public date: Date | null;
    public lastUpdate: TableHistory;
    public columnSize: number;
    public lineSize: number;
    public measureNumber: number;
    public offCriteriaNumber: number;
    public characteristicThicknessNumber: number;
    public thicknessCriteriaNumber: number;
    public weldingCriteriaNumber: number;
    public historicCriteriaNumber: number;
    public system: HydraulicSystemInfos;
    public equipment: EquipmentInfos;
    public zone: ZoneInfos;

    constructor(parameters: OffCriteriaTableResumeDTO) {
        this.id = parameters.id;
        this.runVersion = parameters.runVersion;
        this.lastUpdate = new TableHistory(parameters.lastUpdate);
        this.date = parameters.date != null && parseISO(parameters.date) || null;
        this.columnSize = parameters.columnSize;
        this.lineSize = parameters.lineSize;
        this.measureNumber = parameters.measureNumber;
        this.offCriteriaNumber = parameters.offCriteriaNumber;
        this.characteristicThicknessNumber = parameters.characteristicThicknessNumber;
        this.thicknessCriteriaNumber = parameters.thicknessCriteriaNumber;
        this.weldingCriteriaNumber = parameters.weldingCriteriaNumber;
        this.historicCriteriaNumber = parameters.historicCriteriaNumber;
        this.system = new HydraulicSystemInfos(parameters.system);
        this.equipment = new EquipmentInfos(parameters.equipment);
        this.zone = new ZoneInfos(parameters.zone);
    }
}

export interface OffCriteriaTableResumeDTO {
    id: string;
    runVersion: number;
    date: string | null;
    lastUpdate: TableHistoryDTO;
    columnSize: number;
    lineSize: number;
    measureNumber: number;
    offCriteriaNumber: number;
    characteristicThicknessNumber: number;
    thicknessCriteriaNumber: number;
    weldingCriteriaNumber: number;
    historicCriteriaNumber: number;
    system: HydraulicSystemInfosDTO;
    equipment: EquipmentInfosDTO;
    zone: ZoneInfosDTO;
}