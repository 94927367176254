export class UnreadableLine {
    public line: string;
    public lineNumber: number;

    constructor(parameters: UnreadableLineDTO) {
        this.line = parameters.line;
        this.lineNumber = parameters.lineNumber;
    }
}

export interface UnreadableLineDTO {
    line: string;
    lineNumber: number;
}