import _ from "lodash";
import axios from "axios";

import { HYDRAULIC_SYSTEMS_URL, HYDRAULIC_SYSTEM_STATUS_URL, HYDRAULIC_SYSTEM_URL } from "@/config/urls";
import { IHydraulicSystemService, HydraulicSystemFilterDTO, CreateHydraulicSystemDTO, UpdateHydraulicSystemDTO, UpdateStatusHydraulicSystemDTO } from "@/services";
import { buildURL } from "@/tools";

import { HydraulicSystemDetails, HydraulicSystemDetailsDTO, HydraulicSystemInfos, HydraulicSystemInfosDTO, PowerPlantTree, PowerPlantTreeDTO } from "@/domain";

export class HydraulicSystemService implements IHydraulicSystemService {
    async getMany(filter: HydraulicSystemFilterDTO): Promise<HydraulicSystemInfos[]> {
        const response = await axios.get(buildURL(HYDRAULIC_SYSTEMS_URL, filter));
        const dtos: HydraulicSystemInfosDTO[] = response.data;
        return _.map(dtos, d => new HydraulicSystemInfos(d));
    }

    async create(payload: CreateHydraulicSystemDTO): Promise<HydraulicSystemDetails> {
        const response = await axios.post(HYDRAULIC_SYSTEMS_URL, payload);
        const dto: HydraulicSystemDetailsDTO = response.data;
        return new HydraulicSystemDetails(dto);
    }

    async update(id: string, payload: UpdateHydraulicSystemDTO): Promise<HydraulicSystemDetails> {
        const response = await axios.post(HYDRAULIC_SYSTEM_URL(id), payload);
        const dto: HydraulicSystemDetailsDTO = response.data;
        return new HydraulicSystemDetails(dto);
    }

    async updateStatus(id: string, payload: UpdateStatusHydraulicSystemDTO): Promise<PowerPlantTree> {
        const response = await axios.post(HYDRAULIC_SYSTEM_STATUS_URL(id), payload);
        const dto: PowerPlantTreeDTO = response.data;
        return new PowerPlantTree(dto);
    }
}