import _ from "lodash";
import { GenerationDetails } from "@/domain";

export class GenerationInfos{
    public id: string;
    public name: string;
    public nuclearUnitIds: string[];
    public disabled: boolean;


    constructor(parameters: GenerationInfosDTO) {
        this.id = parameters.id;
        this.name = parameters.name;
        this.nuclearUnitIds = parameters.nuclearUnitIds;
        this.disabled = parameters.disabled;
    }

    static fromDetails(generation: GenerationDetails): GenerationInfos{
        return new GenerationInfos({
            id: generation.id,
            name: generation.name,
            nuclearUnitIds: _.map(generation.nuclearUnits, n => n.id),
            disabled: generation.disabled
        })
    }
}

export interface GenerationInfosDTO{
    id: string;
    name: string;
    nuclearUnitIds: string[];
    disabled: boolean;
}