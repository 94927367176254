import axios from "axios";
import _ from "lodash";

import { NUCLEAR_UNITS_URL, NUCLEAR_UNIT_RESUME_URL, NUCLEAR_UNIT_STATUS_URL } from "@/config/urls";
import { INuclearUnitService, NuclearUnitFilterDTO, CreateNuclearUnitFromScratchDTO, UpdateStatusNuclearUnitDTO } from "@/services";
import { buildURL } from "@/tools";

import { NuclearUnitResume, NuclearUnitResumeDTO, PowerPlantTree, PowerPlantTreeDTO } from "@/domain";
import { NuclearUnitInfos, NuclearUnitInfosDTO, } from "prometheus-synced-ui";

export class NuclearUnitService implements INuclearUnitService {
    async getMany(filter: NuclearUnitFilterDTO): Promise<NuclearUnitInfos[]> {
        const response = await axios.get(buildURL(NUCLEAR_UNITS_URL, filter));
        const dtos: NuclearUnitInfosDTO[] = response.data;
        return _.map(dtos, d => new NuclearUnitInfos(d));
    }

    async getResume(): Promise<NuclearUnitResume[]> {
        const response = await axios.get(NUCLEAR_UNIT_RESUME_URL);
        const dtos: NuclearUnitResumeDTO[] = response.data;
        return _.map(dtos, n => new NuclearUnitResume(n));
    }

    async create(payloads: CreateNuclearUnitFromScratchDTO[]): Promise<NuclearUnitInfos[]> {
        const response = await axios.post(NUCLEAR_UNITS_URL, payloads);
        const dtos: NuclearUnitInfosDTO[] = response.data;
        return _.map(dtos, d => new NuclearUnitInfos(d));
    }

    async updateStatus(id: string, payload: UpdateStatusNuclearUnitDTO): Promise<PowerPlantTree> {
        const response = await axios.post(NUCLEAR_UNIT_STATUS_URL(id), payload);
        const dto: PowerPlantTreeDTO = response.data;
        return new PowerPlantTree(dto);
    }
}