import _ from "lodash";
import { ThresholdStep, ThresholdStepDTO } from "@/domain";

export class ThresholdCriteria {
    public steps: ThresholdStep[];
    public onLastMeasures: boolean;
    public onFirstMeasures: boolean;

    constructor(parameters: ThresholdCriteriaDTO) {
        this.steps = _.map(parameters.steps, t => new ThresholdStep(t));
        this.onLastMeasures = parameters.onLastMeasures;
        this.onFirstMeasures = parameters.onFirstMeasures;
    }
}

export interface ThresholdCriteriaDTO {
    steps: ThresholdStepDTO[];
    onLastMeasures: boolean;
    onFirstMeasures: boolean;
}