import { EquipmentBrandInfos, EquipmentBrandInfosDTO } from "@/domain";
import { UtBoardItem } from "prometheus-synced-ui";

export class UtBoardDetails {
    public id: string;
    public serialNumber: string;
    public equipmentType: string;
    public equipmentBrand: EquipmentBrandInfos;
    public pvNumber: string;
    public disabled: boolean;

    constructor(parameters: UtBoardDetailsDTO) {
        this.id = parameters.id;
        this.serialNumber = parameters.serialNumber;
        this.equipmentType = parameters.equipmentType;
        this.equipmentBrand = new EquipmentBrandInfos(parameters.equipmentBrand);
        this.pvNumber = parameters.pvNumber;
        this.disabled = parameters.disabled;
    }

    public toItem(): UtBoardItem {
        return new UtBoardItem({
            id: this.id,
            serialNumber: this.serialNumber,
            equipmentType: this.equipmentType,
            equipmentBrand: new EquipmentBrandInfos({
                id: this.equipmentBrand.id,
                name: this.equipmentBrand.name,
                disabled: this.equipmentBrand.disabled
            }),
            pvNumber: this.pvNumber,
            disabled: this.disabled,
        });
    }
}

export interface UtBoardDetailsDTO {
    id: string;
    serialNumber: string;
    equipmentType: string;
    equipmentBrand: EquipmentBrandInfosDTO;
    pvNumber: string;
    disabled: boolean;
}