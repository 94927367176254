import { EquipmentBrandInfos, EquipmentBrandInfosDTO } from "@/domain";

export class UtGelDetails {
    public id: string;
    public serialNumber: string;
    public equipmentType: string;
    public equipmentBrand: EquipmentBrandInfos;
    public disabled: boolean;

    constructor(parameters: UtGelDetailsDTO) {
        this.id = parameters.id;
        this.serialNumber = parameters.serialNumber;
        this.equipmentType = parameters.equipmentType;
        this.equipmentBrand = new EquipmentBrandInfos(parameters.equipmentBrand);
        this.disabled = parameters.disabled;
    }
}

export interface UtGelDetailsDTO {
    id: string;
    serialNumber: string;
    equipmentType: string;
    equipmentBrand: EquipmentBrandInfosDTO;
    disabled: boolean;
}