import { DeveloperDetails, EquipmentBrandInfos, EquipmentBrandInfosDTO } from "@/domain";

export class DeveloperItem {
    public id: string;
    public serialNumber: string;
    public equipmentType: string;
    public equipmentBrand: EquipmentBrandInfos;
    public pvNumber: string;
    public disabled: boolean;

    constructor(parameters: DeveloperItemDTO) {
        this.id = parameters.id;
        this.serialNumber = parameters.serialNumber;
        this.equipmentType = parameters.equipmentType;
        this.equipmentBrand = new EquipmentBrandInfos(parameters.equipmentBrand);
        this.pvNumber = parameters.pvNumber;
        this.disabled = parameters.disabled;
    }

    static fromDetails(degreaser: DeveloperDetails): DeveloperItem {
        return new DeveloperItem({
            id: degreaser.id,
            serialNumber: degreaser.serialNumber,
            equipmentType: degreaser.equipmentType,
            equipmentBrand: degreaser.equipmentBrand,
            pvNumber: degreaser.pvNumber,
            disabled: degreaser.disabled
        })
    }
}

export interface DeveloperItemDTO {
    id: string;
    serialNumber: string;
    equipmentType: string;
    equipmentBrand: EquipmentBrandInfosDTO;
    pvNumber: string;
    disabled: boolean
}