export class RoughnessCriteria {
    public maximum: number;

    constructor(parameters: RoughnessCriteriaDTO) {
        this.maximum = parameters.maximum;
    }
}

export interface RoughnessCriteriaDTO {
    maximum: number;
}