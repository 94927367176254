import { SocietyDetails } from "@/domain";

export class SocietyInfos {
    public id: string;
    public name: string;
    public disabled: boolean;

    constructor(parameters: SocietyInfosDTO) {
        this.id = parameters.id;
        this.name = parameters.name;
        this.disabled = parameters.disabled;
    }

    static fromDetails(society: SocietyDetails): SocietyInfos {
        return new SocietyInfos({
            id: society.id,
            name: society.name,
            disabled: society.disabled
        })
    }
}

export interface SocietyInfosDTO {
    id: string;
    name: string;
    disabled: boolean;
}