export class ExamReportInfos {
    public id: string;
    public number: string;
    public operationalFileId: string;
    public zoneId: string;
    public measuringRunIds: string[];

    constructor(parameters: ExamReportInfosDTO) {
        this.id = parameters.id;
        this.number = parameters.number;
        this.operationalFileId = parameters.operationalFileId;
        this.zoneId = parameters.zoneId;
        this.measuringRunIds = parameters.measuringRunIds;
    }
}

export interface ExamReportInfosDTO {
    id: string;
    number: string;
    operationalFileId: string;
    zoneId: string;
    measuringRunIds: string[];
}