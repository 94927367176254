import _ from "lodash";
import axios from "axios";

import { buildURL } from "@/tools";
import { GENERATIONS_URL, GENERATION_URL } from "@/config/urls";

import { GenerationDetails, GenerationDetailsDTO, GenerationInfos, GenerationInfosDTO } from "@/domain";
import { CreateGenerationDTO, GenerationFilterDTO, IGenerationService, UpdateGenerationDTO } from "@/services";

export class GenerationService implements IGenerationService {
    async getMany(filter: GenerationFilterDTO): Promise<GenerationInfos[]> {
        const response = await axios.get(buildURL(GENERATIONS_URL, filter));
        const dtos: GenerationInfosDTO[] = response.data;
        return _.map(dtos, d => new GenerationInfos(d));
    }

    async create(payload: CreateGenerationDTO): Promise<GenerationDetails> {
        const response = await axios.post(GENERATIONS_URL, payload);
        const dto: GenerationDetailsDTO = response.data;
        return new GenerationDetails(dto);
    }

    async update(id: string, payload: UpdateGenerationDTO): Promise<GenerationDetails> {
        const response = await axios.post(GENERATION_URL(id), payload);
        const dto: GenerationDetailsDTO = response.data;
        return new GenerationDetails(dto);
    }
}
