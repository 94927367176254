import { parseISO } from "date-fns";

import { VisitInfos, VisitInfosDTO, TableOrigin } from "prometheus-synced-ui";

export class MeasureTableEssential {
    public id: string;
    public version: number;
    public columnSize: number;
    public lineSize: number;
    public containsMeasures: boolean;
    public date: Date | null;
    public origin: TableOrigin;
    public visit: VisitInfos;

    constructor(parameters: MeasureTableEssentialDTO) {
        this.id = parameters.id;
        this.version = parameters.version;
        this.columnSize = parameters.columnSize;
        this.lineSize = parameters.lineSize;
        this.containsMeasures = parameters.containsMeasures;
        this.date = parameters.date != null && parseISO(parameters.date) || null;
        this.origin = parameters.origin;
        this.visit = new VisitInfos(parameters.visit);
    }
}

export interface MeasureTableEssentialDTO {
    id: string;
    version: number;
    columnSize: number;
    lineSize: number;
    containsMeasures: boolean;
    date: string | null;
    origin: TableOrigin;
    visit: VisitInfosDTO;
}