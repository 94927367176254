import { MaterialShadeInfos, MaterialShadeInfosDTO, V1CalibrationBlockDetails } from "@/domain";

export class V1CalibrationBlockItem {
    public id: string;
    public serialNumber: string;
    public minDimension: number;
    public maxDimension: number;
    public materialShade: MaterialShadeInfos;
    public pvNumber: string;
    public celerity: number;
    public disabled: boolean;

    constructor(parameters: V1CalibrationBlockItemDTO) {
        this.id = parameters.id;
        this.serialNumber = parameters.serialNumber;
        this.minDimension = parameters.minDimension;
        this.maxDimension = parameters.maxDimension;
        this.materialShade = new MaterialShadeInfos(parameters.materialShade);
        this.pvNumber = parameters.pvNumber;
        this.celerity = parameters.celerity;
        this.disabled = parameters.disabled;
    }

    static fromDetails(block: V1CalibrationBlockDetails): V1CalibrationBlockItem {
        return new V1CalibrationBlockItem({
            id: block.id,
            serialNumber: block.serialNumber,
            celerity: block.celerity,
            materialShade: block.materialShade,
            maxDimension: block.maxDimension,
            minDimension: block.minDimension,
            pvNumber: block.pvNumber,
            disabled: block.disabled
        });
    }
}

export interface V1CalibrationBlockItemDTO {
    id: string;
    serialNumber: string;
    minDimension: number;
    maxDimension: number;
    materialShade: MaterialShadeInfosDTO;
    pvNumber: string;
    celerity: number;
    disabled: boolean;
}