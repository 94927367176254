export class UnimportableData {
    public lineNumber: number;
    public error: string;
    public row: string;

    constructor(parameters: UnimportableDataDTO) {
        this.lineNumber = parameters.lineNumber;
        this.error = parameters.error;
        this.row = parameters.row;
    }
}

export interface UnimportableDataDTO {
    lineNumber: number;
    error: string;
    row: string;
}