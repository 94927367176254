import _ from "lodash";
import { HydraulicSystemGroupDetails } from "@/domain";

export class HydraulicSystemGroupInfos {
    public id: string;
    public name: string;
    public monogram: string;
    public systemIds: string[];
    public disabled: boolean;

    constructor(parameters: HydraulicSystemGroupInfosDTO) {
        this.id = parameters.id;
        this.name = parameters.name;
        this.monogram = parameters.monogram;
        this.systemIds = parameters.systemIds;
        this.disabled = parameters.disabled;
    }

    static fromDetails(group: HydraulicSystemGroupDetails): HydraulicSystemGroupInfos {
        return new HydraulicSystemGroupInfos({
            id: group.id,
            name: group.name,
            monogram: group.monogram,
            systemIds: _.map(group.systems, s => s.id),
            disabled: group.disabled
        });
    }
}

export interface HydraulicSystemGroupInfosDTO {
    id: string;
    name: string;
    monogram: string;
    systemIds: string[];
    disabled: boolean;
}