import _ from "lodash";
import { NuclearUnitInfos, NuclearUnitInfosDTO } from "prometheus-synced-ui";

export class GenerationDetails {
    public id: string;
    public name: string;
    public nuclearUnits: NuclearUnitInfos[];
    public disabled: boolean;

    constructor(parameters: GenerationDetailsDTO) {
        this.id = parameters.id;
        this.name = parameters.name;
        this.nuclearUnits = _.map(parameters.nuclearUnits, n => new NuclearUnitInfos(n));
        this.disabled = parameters.disabled;
    }
}

export interface GenerationDetailsDTO {
    id: string;
    name: string;
    nuclearUnits: NuclearUnitInfosDTO[];
    disabled: boolean;
}