import axios from 'axios';

import { EDF_LOGIN_URL, LOGOUT_URL } from '@/config/urls';

import { EDFAccountLoginDTO, IAccountService } from '@/services/interfaces';


export class AccountService implements IAccountService {
    async loginEDF(payload: EDFAccountLoginDTO): Promise<void> {
        await axios.post(EDF_LOGIN_URL, payload);
    }

    async logout(): Promise<void> {
        await axios.post(LOGOUT_URL);
    }
}