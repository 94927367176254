import _ from 'lodash';

export function buildURL(url: string, params?: any, paramsSerializer?: (params: any[]) => any[]) {

    if (params) {
        params = clear(params);

        params = flat(params);
    }

    const result: string[] = []

    for (const key in params) {
        const index = _.lastIndexOf(key, ".");
        const end = key.substring(index + 1);

        if (!isNaN(parseInt(end))) {
            result.push(key.substring(0, index) + "=" + params[key])
        }
        else {
            result.push(key + "=" + params[key])
        }
    }

    params = _.join(result, "&");

    //return axiosBuildURL(url, params, paramsSerializer);
    return url + "?" + params;
}

function clear(params: any) {
    for (const param in params) {
        if (!params[param])
            delete params[param];
    }

    return params;
}

function flat(obj: any) {
    const result: { [key: string]: any } = {}
    for (const key in obj) {
        const flatted = deepFlat(key, obj[key]);
        for (const flat of flatted) {
            result[flat.key] = flat.value;
        }
    }
    return result;
}

function deepFlat(key: string, value: any): { key: string, value: any }[] {
    if (typeof value !== 'object' || value === null || value instanceof Date) {
        return [{ key, value }];
    }
    else {
        const result = [];
        for (const subKey in value) {
            result.push(...deepFlat(key + "." + subKey, value[subKey]));
        }
        return result;
    }
}
