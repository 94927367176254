import * as SERVICES from "./services";
import * as PROVIDERS from "./providers";

export const PROVIDER = "provider";
export const USER = "user";
export const SERVER_CONFIG = "serverConfig";
const EMPTY = "—";

export {
    SERVICES, PROVIDERS, EMPTY
}