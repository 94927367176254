import { DependencyContainer } from "tsyringe";

import { PROVIDERS as P } from "@/config/literals";

import { IEquipmentComponentProvider } from "@/providers";

import { EquipmentComponentProvider } from "@/views/DatabaseHandling/Equipments/provider";

export function addProviders(container: DependencyContainer): void {
   container.registerSingleton<IEquipmentComponentProvider>(P.EQUIPMENT_COMPONENT_PROVIDER, EquipmentComponentProvider);
}