import { CreateProceadureDTO, UpdateProceadureDTO, UpsertCalibrationCriteriaDTO, UpsertRoughnessCriteriaDTO, UpsertTemperatureCriteriaDTO, UpsertThicknessCriteriaDTO, UpsertThresholdCriteriaDTO, UpsertThresholdStepDTO, UpsertUncertaintyStepDTO, UpsertWeldingCriteriaDTO } from "@/services";

// Criterias
function temperatureCriteriaToFormData(temperatureCriteria: UpsertTemperatureCriteriaDTO, formData: FormData): FormData {
    formData.append('temperatureCriteria.minimum', temperatureCriteria.minimum.toString());
    formData.append('temperatureCriteria.maximum', temperatureCriteria.maximum.toString());
    formData.append('temperatureCriteria.delta', temperatureCriteria.delta && temperatureCriteria.delta.toString() || "");
    return formData;
}

function thicknessCriteriaToFormData(thicknessCriteria: UpsertThicknessCriteriaDTO, formData: FormData): FormData {
    formData.append('thicknessCriteria.minimum', thicknessCriteria.minimum.toString());
    formData.append('thicknessCriteria.maximum', thicknessCriteria.maximum.toString());
    return formData;
}

function roughnessCriteriaToFormData(roughnessCriteria: UpsertRoughnessCriteriaDTO, formData: FormData): FormData {
    formData.append('roughnessCriteria.maximum', roughnessCriteria.maximum.toString());
    return formData;
}

function weldingCriteriaToFormData(weldingCriteria: UpsertWeldingCriteriaDTO, formData: FormData): FormData {
    formData.append('weldingCriteria.deltaPercentage', weldingCriteria.deltaPercentage.toString());
    for (let i = 0; i < weldingCriteria.constantDeltas.length; i++) {
        formData.append("weldingCriteria.constantDeltas", weldingCriteria.constantDeltas[i].toString());
    }
    return formData;
}

function calibrationCriteriaToFormData(calibrationCriteria: UpsertCalibrationCriteriaDTO, formData: FormData): FormData {
    formData.append('calibrationCriteria.materialShadeId', calibrationCriteria.materialShadeId);
    formData.append('calibrationCriteria.celerityValue', calibrationCriteria.celerityValue.toString());
    formData.append('calibrationCriteria.celerityRange', calibrationCriteria.celerityRange.toString());
    return formData;
}


// Uncertainty Steps
function commonUncertaintyStepToFormData(index: number, uncertaintySteps: UpsertUncertaintyStepDTO[], formData: FormData): FormData {
    formData.append(`uncertaintySteps[${index}].uncertaintyValue`, uncertaintySteps[index].uncertaintyValue.toString());
    formData.append(`uncertaintySteps[${index}].lowLimit`, uncertaintySteps[index].lowLimit.toString());
    formData.append(`uncertaintySteps[${index}].highLimit`, uncertaintySteps[index].highLimit != null ?
        uncertaintySteps[index].highLimit!.toString() : "");
    return formData;
}

function upsertUncertaintyStepsToFormData(uncertaintySteps: UpsertUncertaintyStepDTO[], formData: FormData): FormData {
    for (let i = 0; i < uncertaintySteps.length; i++) {
        formData = commonUncertaintyStepToFormData(i, uncertaintySteps, formData);
    }
    return formData;
}

// Threshold Criteria
function upsertThresholdCriteriaToFormData(thresholdCriteria: UpsertThresholdCriteriaDTO, formData: FormData): FormData {
    formData.append(`thresholdCriteria.onLastMeasures`, thresholdCriteria.onLastMeasures.toString());
    formData.append(`thresholdCriteria.onFirstMeasures`, thresholdCriteria.onFirstMeasures.toString());
    formData = upsertThresholdStepsToFormData(thresholdCriteria.steps, formData);
    return formData;
}

// Threshold Steps
function commonThresholdStepToFormData(index: number, steps: UpsertThresholdStepDTO[], formData: FormData): FormData {
    formData.append(`thresholdCriteria.steps[${index}].thresholdValue`, steps[index].thresholdValue.toString());
    formData.append(`thresholdCriteria.steps[${index}].lowLimit`, steps[index].lowLimit.toString());
    formData.append(`thresholdCriteria.steps[${index}].highLimit`, steps[index].highLimit.toString());
    return formData;
}

function upsertThresholdStepsToFormData(steps: UpsertThresholdStepDTO[], formData: FormData): FormData {
    for (let i = 0; i < steps.length; i++) {
        formData = commonThresholdStepToFormData(i, steps, formData);
    }
    return formData;
}

// Common
function commonPropertiesToFormData(payload: CreateProceadureDTO): FormData {
    const formData = new FormData();
    formData.append('name', payload.name);
    // Files
    for (let i = 0; i < payload.files.length; i++) {
        formData.append("files", payload.files[i], payload.files[i].name);
    }
    return formData
}

export function createProceadureToFormData(payload: CreateProceadureDTO): FormData {
    let formData = commonPropertiesToFormData(payload);

    // Criterias    
    if (payload.temperatureCriteria != null) {
        formData = temperatureCriteriaToFormData(payload.temperatureCriteria, formData);
    }
    if (payload.thicknessCriteria != null) {
        formData = thicknessCriteriaToFormData(payload.thicknessCriteria, formData);
    }
    if (payload.roughnessCriteria != null) {
        formData = roughnessCriteriaToFormData(payload.roughnessCriteria, formData);
    }
    if (payload.weldingCriteria != null) {
        formData = weldingCriteriaToFormData(payload.weldingCriteria, formData);
    }
    if (payload.calibrationCriteria != null) {
        formData = calibrationCriteriaToFormData(payload.calibrationCriteria, formData);
    }
    // Steps
    formData = upsertUncertaintyStepsToFormData(payload.uncertaintySteps, formData);
    if (payload.thresholdCriteria != null) {
        formData = upsertThresholdCriteriaToFormData(payload.thresholdCriteria, formData);
    }

    return formData;
}

export function updateProceadureToFormData(payload: UpdateProceadureDTO): FormData {
    let formData = commonPropertiesToFormData(payload);

    // Criterias    
    if (payload.temperatureCriteria != null) {
        formData = temperatureCriteriaToFormData(payload.temperatureCriteria, formData);
    }
    if (payload.thicknessCriteria != null) {
        formData = thicknessCriteriaToFormData(payload.thicknessCriteria, formData);
    }
    if (payload.roughnessCriteria != null) {
        formData = roughnessCriteriaToFormData(payload.roughnessCriteria, formData);
    }
    if (payload.weldingCriteria != null) {
        formData = weldingCriteriaToFormData(payload.weldingCriteria, formData);
    }
    if (payload.calibrationCriteria != null) {
        formData = calibrationCriteriaToFormData(payload.calibrationCriteria, formData);
    }
    // Steps
    formData = upsertUncertaintyStepsToFormData(payload.uncertaintySteps, formData);
    if (payload.thresholdCriteria != null) {
        formData = upsertThresholdCriteriaToFormData(payload.thresholdCriteria, formData);
    }

    for (let i = 0; i < payload.fileIdsToDelete.length; i++) {
        formData.append("fileIdsToDelete", payload.fileIdsToDelete[i]);
    }
    formData.append('disabled', payload.disabled.toString());

    return formData
}

