import { parseISO } from "date-fns";

import {
    VisitInfos, VisitInfosDTO, NuclearUnitInfos, ZoneInfos, TableOrigin,
    NuclearUnitInfosDTO, PowerPlantInfos, PowerPlantInfosDTO, ZoneInfosDTO
} from "prometheus-synced-ui";

import {
    EquipmentInfos, EquipmentInfosDTO, HydraulicSystemInfos, HydraulicSystemInfosDTO,
    TableHistory, TableHistoryDTO
} from "@/domain";

export class MeasureTableResume {
    public id: string;
    public version: number;
    public date: Date | null;
    public creation: TableHistory;
    public lastUpdate: TableHistory;
    public origin: TableOrigin;
    public columnSize: number;
    public lineSize: number;
    public containsMeasures: boolean;
    public isDeletable: boolean;
    public visit: VisitInfos;
    public powerPlant: PowerPlantInfos;
    public nuclearUnit: NuclearUnitInfos;
    public system: HydraulicSystemInfos;
    public equipment: EquipmentInfos;
    public zone: ZoneInfos;

    constructor(parameters: MeasureTableResumeDTO) {
        this.id = parameters.id;
        this.version = parameters.version;
        this.creation = new TableHistory(parameters.creation);
        this.lastUpdate = new TableHistory(parameters.lastUpdate);
        this.date = parameters.date != null && parseISO(parameters.date) || null;
        this.origin = parameters.origin;
        this.columnSize = parameters.columnSize;
        this.lineSize = parameters.lineSize;
        this.containsMeasures = parameters.containsMeasures;
        this.isDeletable = parameters.isDeletable;
        this.visit = new VisitInfos(parameters.visit);
        this.powerPlant = new PowerPlantInfos(parameters.powerPlant);
        this.nuclearUnit = new NuclearUnitInfos(parameters.nuclearUnit);
        this.system = new HydraulicSystemInfos(parameters.system);
        this.equipment = new EquipmentInfos(parameters.equipment);
        this.zone = new ZoneInfos(parameters.zone);
    }
}

export interface MeasureTableResumeDTO {
    id: string;
    version: number;
    date: string | null;
    creation: TableHistoryDTO;
    lastUpdate: TableHistoryDTO;
    origin: TableOrigin;
    columnSize: number;
    lineSize: number;
    containsMeasures: boolean;
    isDeletable: boolean;
    visit: VisitInfosDTO;
    powerPlant: PowerPlantInfosDTO;
    nuclearUnit: NuclearUnitInfosDTO;
    system: HydraulicSystemInfosDTO;
    equipment: EquipmentInfosDTO;
    zone: ZoneInfosDTO;
}