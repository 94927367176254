import axios from "axios";
import _ from "lodash";

import { EXCEL_DATABASES_URL } from "@/config/urls";

import { FailedSheet, FailedSheetDTO } from "@/domain";

import { IExcelDatabaseService } from "@/services/interfaces";

export class ExcelDatabaseService implements IExcelDatabaseService {
    async import(file: File): Promise<FailedSheet[]> {
        const formData = new FormData();
        formData.append('file', file);

        const response = await axios.post(EXCEL_DATABASES_URL, formData);
        const dtos: FailedSheetDTO[] = response.data;
        return _.map(dtos, d => new FailedSheet(d));
    }
}