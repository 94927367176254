import axios from "axios";
import _ from "lodash";

import { STEP_CALIBRATION_BLOCKS_URL, STEP_CALIBRATION_BLOCK_URL } from "@/config/urls";
import { buildURL } from "@/tools";

import { StepCalibrationBlockDetails, StepCalibrationBlockDetailsDTO, StepCalibrationBlockItem, StepCalibrationBlockItemDTO } from "@/domain";
import { CreateStepCalibrationBlockDTO, IStepCalibrationBlockService, UpdateStepCalibrationBlockDTO, StepCalibrationBlockFilterDTO } from "@/services/interfaces";

export class StepCalibrationBlockService implements IStepCalibrationBlockService {
    async getMany(filter: StepCalibrationBlockFilterDTO): Promise<StepCalibrationBlockItem[]> {
        const response = await axios.get(buildURL(STEP_CALIBRATION_BLOCKS_URL, filter));
        const dtos: StepCalibrationBlockItemDTO[] = response.data;
        return _.map(dtos, d => new StepCalibrationBlockItem(d));
    }

    async create(payload: CreateStepCalibrationBlockDTO): Promise<StepCalibrationBlockDetails> {
        const response = await axios.post(STEP_CALIBRATION_BLOCKS_URL, payload);
        const dto: StepCalibrationBlockDetailsDTO = response.data;
        return new StepCalibrationBlockDetails(dto);
    }

    async update(id: string, payload: UpdateStepCalibrationBlockDTO): Promise<StepCalibrationBlockDetails> {
        const response = await axios.post(STEP_CALIBRATION_BLOCK_URL(id), payload);
        const dto: StepCalibrationBlockDetailsDTO = response.data;
        return new StepCalibrationBlockDetails(dto);
    }
}