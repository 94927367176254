import { HydraulicSystemDetails } from "@/domain";

export class HydraulicSystemInfos {
    public id: string;
    public name: string;
    public trigram: string;
    public groupId: string;
    public nuclearUnitId: string;
    public disabled: boolean;

    constructor(parameters: HydraulicSystemInfosDTO) {
        this.id = parameters.id;
        this.name = parameters.name;
        this.trigram = parameters.trigram;
        this.groupId = parameters.groupId;
        this.nuclearUnitId = parameters.nuclearUnitId;
        this.disabled = parameters.disabled;
    }

    static fromDetails(system: HydraulicSystemDetails): HydraulicSystemInfos {
        return new HydraulicSystemInfos({
            id: system.id,
            name: system.name,
            trigram: system.trigram,
            groupId: system.group.id,
            nuclearUnitId: system.nuclearUnit.id,
            disabled: system.disabled
        });
    }
}
export interface HydraulicSystemInfosDTO {
    id: string;
    name: string;
    trigram: string;
    groupId: string;
    nuclearUnitId: string;
    disabled: boolean;
}