export const EquipmentComponents: EquipmentComponents = {
    "c982bdcf-1a7a-4f5e-8591-de345a4be15d": () => import("@/components/Core/Table/Equipments/DevelopersTable.vue"),
    "45a8dfd6-28eb-4a6e-9738-9313dc91941c": () => import("@/components/Core/Table/Equipments/DegreasersTable.vue"),
    "56d71f19-0c94-49a4-92a1-40c0aede1b94": () => import("@/components/Core/Table/Equipments/HygrometersTable.vue"),
    "066f0fb7-4039-41a0-80da-b32b01fa25c2": () => import("@/components/Core/Table/Equipments/LuxmetersTable.vue"),
    "19ab07a0-c8b0-4f41-8b22-c5aa83163715": () => import("@/components/Core/Table/Equipments/PenetrantsTable.vue"),
    "fac1e33b-0819-45c3-b540-cdc3921d6b00": () => import("@/components/Core/Table/Equipments/RoughometersTable.vue"),
    "64574c47-d6c6-4d3c-b352-bea8fa084ba6": () => import("@/components/Core/Table/Equipments/ThermometersTable.vue"),
    "1fa5b4f2-7e10-415e-ae3b-e500ab35fa9d": () => import("@/components/Core/Table/Equipments/UtProbesTable.vue"),
    "e4d77c9e-e405-4dc0-a16f-1198dd6f6b14": () => import("@/components/Core/Table/Equipments/UtBoardsTable.vue"),
    "514e52ad-5de9-4db7-ad29-b6ee6eb216b4": () => import("@/components/Core/Table/Equipments/UtGelsTable.vue"),
    "d5c69c62-3092-480e-b0cc-4e4d7d6af06a": () => import("@/components/CalibrationBlockMenu.vue"),
}

interface EquipmentComponents {
    [key: string]: any;
}
