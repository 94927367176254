import axios from "axios";
import _ from "lodash";

import { UT_GELS_URL, UT_GEL_URL } from "@/config/urls";
import { buildURL } from "@/tools";

import { UtGelDetails, UtGelDetailsDTO, UtGelItem, UtGelItemDTO } from "@/domain";
import { CreateUtGelDTO, IUtGelService, UpdateUtGelDTO, UtGelFilterDTO } from "@/services";

export class UtGelService implements IUtGelService {
    async getMany(filter: UtGelFilterDTO): Promise<UtGelItem[]> {
        const response = await axios.get(buildURL(UT_GELS_URL, filter));
        const dtos: UtGelItemDTO[] = response.data;
        return _.map(dtos, d => new UtGelItem(d));
    }

    async create(payload: CreateUtGelDTO): Promise<UtGelDetails> {
        const response = await axios.post(UT_GELS_URL, payload);
        const dto: UtGelDetailsDTO = response.data;
        return new UtGelDetails(dto);
    }

    async update(id: string, payload: UpdateUtGelDTO): Promise<UtGelDetails> {
        const response = await axios.post(UT_GEL_URL(id), payload);
        const dto: UtGelDetailsDTO = response.data;
        return new UtGelDetails(dto);
    }
}