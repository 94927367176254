import { parseISO } from "date-fns";
import {
    EnvironmentMeasureInfos, EnvironmentMeasureInfosDTO, ExamReportInfos, ExamReportInfosDTO, MeasureTableInfos, MeasureTableInfosDTO,
    MeasuringRunStatus, OffTableMeasureInfos, OffTableMeasureInfosDTO, RunParametersInfos, RunParametersInfosDTO, ThicknessParamInfos,
    ThicknessParamInfosDTO, ThicknessType,
} from "@/domain";

import { UserInfos, UserInfosDTO } from "prometheus-synced-ui";

export class MeasuringRunDetails {
    public id: string;
    public version: number;
    public status: MeasuringRunStatus;
    public examReport: ExamReportInfos;
    public creator: UserInfos;
    public controller: UserInfos | null;
    public creationDate: Date;
    // Inspections
    public firstInspection: MeasureTableInfos | null;
    public lastInspection: MeasureTableInfos | null;
    public currentInspection: MeasureTableInfos;
    //Parameters 
    public analystParameters: RunParametersInfos;
    public controllerParameters: RunParametersInfos | null;
    // Thickness
    public intrados: ThicknessParamInfos | null;
    public extrados: ThicknessParamInfos | null;
    public environmentMeasures: EnvironmentMeasureInfos | null;
    public offTableMeasure: OffTableMeasureInfos | null;
    public thresholdValue: number | null;
    public uncertaintyValue: number | null;
    public comment: string | null;
    public selectedThickness: ThicknessType;
    public advisedProceadure: string | null;
    public advisedUncertaintyProceadure: string | null;
    public isAssignable: boolean;

    constructor(parameters: MeasuringRunDetailsDTO) {
        this.id = parameters.id;
        this.version = parameters.version;
        this.status = parameters.status;
        this.examReport = new ExamReportInfos(parameters.examReport);
        this.creator = new UserInfos(parameters.creator);
        this.controller = parameters.controller != null ? new UserInfos(parameters.controller) : null;
        this.creationDate = parseISO(parameters.creationDate);
        this.firstInspection = parameters.firstInspection != null ? new MeasureTableInfos(parameters.firstInspection) : null;
        this.lastInspection = parameters.lastInspection != null ? new MeasureTableInfos(parameters.lastInspection) : null;
        this.currentInspection = new MeasureTableInfos(parameters.currentInspection);
        this.analystParameters = new RunParametersInfos(parameters.analystParameters);
        this.controllerParameters = parameters.controllerParameters != null ? new RunParametersInfos(parameters.controllerParameters) : null;
        this.intrados = parameters.intrados ? new ThicknessParamInfos(parameters.intrados) : null;
        this.extrados = parameters.extrados ? new ThicknessParamInfos(parameters.extrados) : null;
        this.environmentMeasures = parameters.environmentMeasures != null ? new EnvironmentMeasureInfos(parameters.environmentMeasures) : null;
        this.offTableMeasure = parameters.offTableMeasure != null ? new OffTableMeasureInfos(parameters.offTableMeasure) : null;
        this.selectedThickness = parameters.selectedThickness;
        this.thresholdValue = parameters.thresholdValue;
        this.uncertaintyValue = parameters.uncertaintyValue;
        this.comment = parameters.comment;
        this.advisedProceadure = parameters.advisedProceadure;
        this.advisedUncertaintyProceadure = parameters.advisedUncertaintyProceadure;
        this.isAssignable = parameters.isAssignable;
    }
}

export interface MeasuringRunDetailsDTO {
    id: string;
    version: number;
    status: MeasuringRunStatus;
    examReport: ExamReportInfosDTO;
    creator: UserInfosDTO;
    controller: UserInfosDTO | null;
    creationDate: string;
    firstInspection: MeasureTableInfosDTO | null;
    lastInspection: MeasureTableInfosDTO | null;
    currentInspection: MeasureTableInfosDTO;
    analystParameters: RunParametersInfosDTO;
    controllerParameters: RunParametersInfosDTO | null;
    intrados: ThicknessParamInfosDTO | null;
    extrados: ThicknessParamInfosDTO | null;
    environmentMeasures: EnvironmentMeasureInfosDTO | null;
    offTableMeasure: OffTableMeasureInfosDTO | null;
    thresholdValue: number | null;
    uncertaintyValue: number | null;
    comment: string | null;
    selectedThickness: ThicknessType;
    advisedProceadure: string | null;
    advisedUncertaintyProceadure: string | null;
    isAssignable: boolean;
}