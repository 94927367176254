import axios from "axios";
import _ from "lodash";

import { buildURL } from "@/tools";
import { OPERATIONAL_FILES_URL } from "@/config/urls";

import { OperationalFileInfos, OperationalFileInfosDTO } from "@/domain";
import { OperationalFileFilterDTO, IOperationalFileService } from "@/services/interfaces";

export class OperationalFileService implements IOperationalFileService {
    async getMany(filter: OperationalFileFilterDTO): Promise<OperationalFileInfos[]> {
        const response = await axios.get(buildURL(OPERATIONAL_FILES_URL, filter));
        const dtos: OperationalFileInfosDTO[] = response.data;
        return _.map(dtos, d => new OperationalFileInfos(d));
    }
}