export enum EquipmentCategory {
    unknown = 0,
    degreaser = 1,
    developer = 2,
    hygrometer = 3,
    luxmeter = 4,
    penetrant = 5,
    roughometer = 6,
    thermometer = 7,
    utBoard = 8,
    utGel = 9,
    utProbe = 10,
    calibrationBlock = 11,
}