import _ from "lodash";
import axios from "axios";

import { MATERIAL_SHADES_URL, MATERIAL_SHADE_URL } from "@/config/urls";
import { buildURL } from "@/tools";

import { MaterialShadeDetails, MaterialShadeDetailsDTO, MaterialShadeInfos, MaterialShadeInfosDTO } from "@/domain";
import { CreateMaterialShadeDTO, IMaterialShadeService, MaterialShadeFilterDTO, UpdateMaterialShadeDTO } from "@/services";

export class MaterialShadeService implements IMaterialShadeService {
    async getMany(filter: MaterialShadeFilterDTO): Promise<MaterialShadeInfos[]> {
        const response = await axios.get(buildURL(MATERIAL_SHADES_URL, filter));
        const dtos: MaterialShadeInfosDTO[] = response.data;
        return _.map(dtos, d => new MaterialShadeInfos(d));
    }

    async create(payload: CreateMaterialShadeDTO): Promise<MaterialShadeDetails> {
        const response = await axios.post(MATERIAL_SHADES_URL, payload);
        const dto: MaterialShadeDetailsDTO = response.data;
        return new MaterialShadeDetails(dto);
    }

    async update(id: string, payload: UpdateMaterialShadeDTO): Promise<MaterialShadeDetails> {
        const response = await axios.post(MATERIAL_SHADE_URL(id), payload);
        const dto: MaterialShadeDetailsDTO = response.data;
        return new MaterialShadeDetails(dto);
    }
}