import _ from "lodash";
import axios from "axios";

import {
    MEASURE_TABLE_ESSENTIAL_PARTIAL_ZONE_URL,
    MEASURE_TABLE_ESSENTIAL_URL, MEASURE_TABLE_IMPORT_HISTORIC_URL, MEASURE_TABLE_OFF_CRITERIA_URL,
    MEASURE_TABLE_RESUME_URL, MEASURE_TABLE_URL
} from "@/config/urls";
import { buildURL } from "@/tools";

import {
    MeasureTableResume, MeasureTableResumeDTO, MeasureTableEssential,
    MeasureTableEssentialDTO, OffCriteriaTableResume, OffCriteriaTableResumeDTO
} from "@/domain";

import { MeasureTableDetails, MeasureTableDetailsDTO, } from "prometheus-synced-ui";

import {
    IMeasureTableService, MeasureTableResumeFilterDTO, MeasureTableEssentialFilterDTO, OffCriteriaTableResumeFilterDTO,
    MeasureTableEssentialPartialZoneFilterDTO,
    ImportHistoricDTO
} from "@/services/interfaces";

export class MeasureTableService implements IMeasureTableService {
    async get(id: string): Promise<MeasureTableDetails> {
        const response = await axios.get(MEASURE_TABLE_URL(id));
        const dto: MeasureTableDetailsDTO = response.data;
        return new MeasureTableDetails(dto);
    }

    async getResume(filter: MeasureTableResumeFilterDTO): Promise<MeasureTableResume[]> {
        const response = await axios.get(buildURL(MEASURE_TABLE_RESUME_URL, filter));
        const dtos: MeasureTableResumeDTO[] = response.data;
        return _.map(dtos, r => new MeasureTableResume(r));
    }

    async getEssential(filter: MeasureTableEssentialFilterDTO): Promise<MeasureTableEssential[]> {
        const response = await axios.get(buildURL(MEASURE_TABLE_ESSENTIAL_URL, filter));
        const dtos: MeasureTableEssentialDTO[] = response.data;
        return _.map(dtos, r => new MeasureTableEssential(r));
    }

    async getEssentialByPartialZone(filter: MeasureTableEssentialPartialZoneFilterDTO): Promise<MeasureTableEssential[]> {
        const response = await axios.get(buildURL(MEASURE_TABLE_ESSENTIAL_PARTIAL_ZONE_URL, filter));
        const dtos: MeasureTableEssentialDTO[] = response.data;
        return _.map(dtos, r => new MeasureTableEssential(r));
    }

    async getOffCriteria(filter: OffCriteriaTableResumeFilterDTO): Promise<OffCriteriaTableResume[]> {
        const response = await axios.get(buildURL(MEASURE_TABLE_OFF_CRITERIA_URL, filter));
        const dtos: OffCriteriaTableResumeDTO[] = response.data;
        return _.map(dtos, r => new OffCriteriaTableResume(r));
    }

    async importHistoric(payload: ImportHistoricDTO): Promise<void> {
        const formData = new FormData();
        formData.append('systemId', payload.systemId);
        formData.append('equipmentId', payload.equipmentId != null ? payload.equipmentId : "");
        formData.append('equipmentName', payload.equipmentName != null ? payload.equipmentName : "");
        formData.append('equipmentNgram', payload.equipmentNgram != null ? payload.equipmentNgram : "");
        formData.append('zoneId', payload.zoneId != null ? payload.zoneId : "");
        formData.append('zoneTag', payload.zoneTag != null ? payload.zoneTag : "");
        formData.append("file", payload.file);

        await axios.post(MEASURE_TABLE_IMPORT_HISTORIC_URL, formData);
    }

    async remove(id: string): Promise<void> {
        await axios.delete(MEASURE_TABLE_URL(id));
    }
}