import axios from "axios";
import _ from "lodash";

import { buildURL } from "@/tools";
import { ROUGHOMETERS_URL, ROUGHOMETER_URL } from "@/config/urls";

import { RoughometerDetails, RoughometerDetailsDTO, RoughometerItem, RoughometerItemDTO } from "@/domain";
import { CreateRoughometerDTO, IRoughometerService, RoughometerFilterDTO, UpdateRoghometerDTO } from "@/services";

export class RoughometerService implements IRoughometerService{
    async getMany(filter: RoughometerFilterDTO): Promise<RoughometerItem[]> {
        const response = await axios.get(buildURL(ROUGHOMETERS_URL, filter));
        const dtos: RoughometerItemDTO[] = response.data;
        return _.map(dtos, d => new RoughometerItem(d));
    }

    async create(payload: CreateRoughometerDTO): Promise<RoughometerDetails> {
        const response = await axios.post(ROUGHOMETERS_URL, payload);
        const dto : RoughometerDetailsDTO = response.data;
        return new RoughometerDetails(dto);
    }

    async update(id: string, payload: UpdateRoghometerDTO): Promise<RoughometerDetails> {
        const response = await axios.post(ROUGHOMETER_URL(id),payload);
        const dto: RoughometerDetailsDTO = response.data;
        return new RoughometerDetails(dto);
    }
}