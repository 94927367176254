import { EquipmentBrandInfos, EquipmentBrandInfosDTO } from "@/domain";
import { UtProbeItem } from "prometheus-synced-ui";

export class UtProbeDetails {
    public id: string;
    public serialNumber: string;
    public equipmentType: string;
    public equipmentBrand: EquipmentBrandInfos;
    public diameter: number;
    public frequency: number;
    public pvNumber: string;
    public disabled: boolean;

    constructor(parameters: UtProbeDetailsDTO) {
        this.id = parameters.id;
        this.serialNumber = parameters.serialNumber;
        this.equipmentType = parameters.equipmentType;
        this.equipmentBrand = parameters.equipmentBrand;
        this.diameter = parameters.diameter;
        this.frequency = parameters.frequency;
        this.pvNumber = parameters.pvNumber;
        this.disabled = parameters.disabled;
    }

    public toItem(): UtProbeItem {
        return new UtProbeItem({
            id: this.id,
            serialNumber: this.serialNumber,
            equipmentType: this.equipmentType,
            equipmentBrand: new EquipmentBrandInfos({
                id: this.equipmentBrand.id,
                name: this.equipmentBrand.name,
                disabled: this.equipmentBrand.disabled
            }),
            diameter: this.diameter,
            frequency: this.frequency,
            pvNumber: this.pvNumber,
            disabled: this.disabled
        })
    }
}

export interface UtProbeDetailsDTO {
    id: string;
    serialNumber: string;
    equipmentType: string;
    equipmentBrand: EquipmentBrandInfosDTO;
    diameter: number;
    frequency: number;
    pvNumber: string;
    disabled: boolean;
}