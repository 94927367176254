import _ from "lodash";
import axios from "axios";

import { THERMOMETER_URL, THERMOMETERS_URL } from "@/config/urls";
import { buildURL } from "@/tools";

import { ThermometerDetails, ThermometerDetailsDTO, ThermometerItem, ThermometerItemDTO } from "@/domain";
import { CreateThermometerDTO, IThermometerService, ThermometerFilterDTO, UpdateThermometerDTO } from "@/services";

export class ThermometerService implements IThermometerService {
    async getMany(filter: ThermometerFilterDTO): Promise<ThermometerItem[]> {
        const response = await axios.get(buildURL(THERMOMETERS_URL, filter));
        const dtos: ThermometerItemDTO[] = response.data;
        return _.map(dtos, d => new ThermometerItem(d));
    }

    async create(payload: CreateThermometerDTO): Promise<ThermometerDetails> {
        const response = await axios.post(THERMOMETERS_URL, payload);
        const dto: ThermometerDetailsDTO = response.data;
        return new ThermometerDetails(dto);
    }

    async update(id: string, payload: UpdateThermometerDTO): Promise<ThermometerDetails> {
        const response = await axios.post(THERMOMETER_URL(id), payload);
        const dto: ThermometerDetailsDTO = response.data;
        return new ThermometerDetails(dto);
    }
}