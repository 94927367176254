import { lightFormat, parseISO } from "date-fns";
import { EquipmentBrandInfos, EquipmentBrandInfosDTO, ThermometerDetails } from "@/domain";

export class ThermometerItem {
    public id: string;
    public serialNumber: string;
    public equipmentType: string;
    public equipmentBrand: EquipmentBrandInfos;
    public validityDate: Date;
    public disabled: boolean;

    constructor(parameters: ThermometerItemDTO) {
        this.id = parameters.id;
        this.serialNumber = parameters.serialNumber;
        this.equipmentType = parameters.equipmentType;
        this.equipmentBrand = new EquipmentBrandInfos(parameters.equipmentBrand);
        this.validityDate = parseISO(parameters.validityDate);
        this.disabled = parameters.disabled;
    }

    static fromDetails(thermometer: ThermometerDetails): ThermometerItem {
        return new ThermometerItem({
            id: thermometer.id,
            equipmentType: thermometer.equipmentType,
            equipmentBrand: thermometer.equipmentBrand,
            serialNumber: thermometer.serialNumber,
            validityDate: lightFormat(thermometer.validityDate, "yyyy-MM-dd"),
            disabled: thermometer.disabled
        })
    }
}

export interface ThermometerItemDTO {
    id: string;
    serialNumber: string;
    equipmentType: string;
    equipmentBrand: EquipmentBrandInfosDTO;
    validityDate: string;
    disabled: boolean;
}