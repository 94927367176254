
import { Component, Vue } from "vue-property-decorator";
import { InjectReactive } from "vue-property-decorator";
import { USER } from "@/config/literals";
import { UserDetails } from "@/domain";

@Component
export default class ToolbarUser extends Vue {
  @InjectReactive(USER)
  user!: UserDetails;

  onLogout() {
    window.location.href = "/api/v1/accounts/logout";
  }
}
