import { lightFormat, parseISO } from "date-fns";
import {
    EnvironmentMeasureInfos, EnvironmentMeasureInfosDTO,
    MeasuringRunDetails, MeasuringRunStatus, RunParametersInfos, RunParametersInfosDTO,
    ThicknessParamInfos, ThicknessParamInfosDTO, ThicknessType
} from "@/domain";

export class MeasuringRunInfos {
    public id: string;
    public version: number;
    public status: MeasuringRunStatus;
    public examReportId: string;
    public creatorId: string;
    public controllerId: string | null;
    public creationDate: Date;
    // Inspections
    public firstInspectionId: string | null;
    public lastInspectionId: string | null;
    public currentInspectionId: string;
    //Parameters 
    public analystParameters: RunParametersInfos;
    public controllerParameters: RunParametersInfos | null;
    // Thickness
    public intrados: ThicknessParamInfos | null;
    public extrados: ThicknessParamInfos | null;
    public environmentMeasures: EnvironmentMeasureInfos | null;
    public offTableMeasureId: string | null;
    public thresholdValue: number | null;
    public uncertaintyValue: number | null;
    public comment: string | null;
    public advisedProceadure: string | null;
    public advisedUncertaintyProceadure: string | null;
    public selectedThickness: ThicknessType;
    public isAssignable: boolean;

    constructor(parameters: MeasuringRunInfosDTO) {
        this.id = parameters.id;
        this.version = parameters.version;
        this.status = parameters.status;
        this.examReportId = parameters.examReportId;
        this.creatorId = parameters.creatorId;
        this.controllerId = parameters.controllerId;
        this.creationDate = parseISO(parameters.creationDate);
        this.firstInspectionId = parameters.firstInspectionId;
        this.lastInspectionId = parameters.lastInspectionId;
        this.currentInspectionId = parameters.currentInspectionId;
        this.analystParameters = new RunParametersInfos(parameters.analystParameters);
        this.controllerParameters = parameters.controllerParameters != null ? new RunParametersInfos(parameters.analystParameters) : null;
        this.intrados = parameters.intrados != null ? new ThicknessParamInfos(parameters.intrados) : null;
        this.extrados = parameters.extrados != null ? new ThicknessParamInfos(parameters.extrados) : null;
        this.environmentMeasures = parameters.environmentMeasures != null ? new EnvironmentMeasureInfos(parameters.environmentMeasures) : null;
        this.offTableMeasureId = parameters.offTableMeasureId;
        this.thresholdValue = parameters.thresholdValue;
        this.uncertaintyValue = parameters.uncertaintyValue;
        this.selectedThickness = parameters.selectedThickness;
        this.comment = parameters.comment;
        this.advisedProceadure = parameters.advisedProceadure;
        this.advisedUncertaintyProceadure = parameters.advisedUncertaintyProceadure;
        this.isAssignable = parameters.isAssignable;
    }

    static fromDetails(run: MeasuringRunDetails): MeasuringRunInfos {
        return new MeasuringRunInfos({
            id: run.id,
            status: run.status,
            version: run.version,
            controllerId: run.controller != null ? run.controller.id : null,
            creationDate: lightFormat(run.creationDate, "yyyy-MM-dd"),
            creatorId: run.creator.id,
            examReportId: run.examReport.id,
            firstInspectionId: run.firstInspection != null ? run.firstInspection.id : null,
            lastInspectionId: run.lastInspection != null ? run.lastInspection.id : null,
            currentInspectionId: run.currentInspection.id,
            analystParameters: run.analystParameters,
            controllerParameters: run.controllerParameters != null ? run.controllerParameters : null,
            intrados: run.intrados != null ? run.intrados : null,
            extrados: run.extrados != null ? run.extrados : null,
            environmentMeasures: run.environmentMeasures != null ? run.environmentMeasures : null,
            offTableMeasureId: run.offTableMeasure != null ? run.offTableMeasure.id : null,
            selectedThickness: run.selectedThickness,
            thresholdValue: run.thresholdValue,
            uncertaintyValue: run.uncertaintyValue,
            comment: run.comment,
            advisedProceadure: run.advisedProceadure,
            advisedUncertaintyProceadure: run.advisedUncertaintyProceadure,
            isAssignable: run.isAssignable
        })
    }
}

export interface MeasuringRunInfosDTO {
    id: string;
    version: number;
    status: MeasuringRunStatus;
    examReportId: string;
    creatorId: string;
    controllerId: string | null;
    creationDate: string;
    firstInspectionId: string | null;
    lastInspectionId: string | null;
    currentInspectionId: string;
    analystParameters: RunParametersInfosDTO;
    controllerParameters: RunParametersInfosDTO | null;
    intrados: ThicknessParamInfosDTO | null;
    extrados: ThicknessParamInfosDTO | null;
    environmentMeasures: EnvironmentMeasureInfosDTO | null;
    offTableMeasureId: string | null;
    thresholdValue: number | null;
    uncertaintyValue: number | null;
    comment: string | null;
    advisedProceadure: string | null;
    advisedUncertaintyProceadure: string | null;
    selectedThickness: ThicknessType;
    isAssignable: boolean;
}