export class ThicknessParamInfos {
    public nominal: number | null;
    public minManufactured: number | null;
    public characterisation: number | null;
    public calculation: number | null;

    constructor(parameters: ThicknessParamInfosDTO) {
        this.nominal = parameters.nominal;
        this.minManufactured = parameters.minManufactured;
        this.characterisation = parameters.characterisation;
        this.calculation = parameters.calculation;
    }
}

export interface ThicknessParamInfosDTO {
    nominal: number | null;
    minManufactured: number | null;
    characterisation: number | null;
    calculation: number | null;
}

