import axios from "axios";
import _ from "lodash";

import { SOCIETIES_URL, SOCIETY_URL } from "@/config/urls/";
import { buildURL } from "@/tools";

import { SocietyDetails, SocietyDetailsDTO, SocietyInfos, SocietyInfosDTO } from "@/domain";
import { CreateSocietyDTO, ISocietyService, SocietyFilterDTO, UpdateSocietyDTO } from "@/services/interfaces";

export class SocietyService implements ISocietyService{
    async get(id: string): Promise<SocietyDetails> {
        const response = await axios.get(SOCIETY_URL(id));
        const dto : SocietyDetailsDTO = response.data;
        return new SocietyDetails(dto);
    }
    async getMany(filter: SocietyFilterDTO): Promise<SocietyInfos[]> {
        const response = await axios.get(buildURL(SOCIETIES_URL, filter));
        const dtos : SocietyInfosDTO[] = response.data;
        return  _.map(dtos, d => new SocietyInfos(d));
    }
    async create(payload: CreateSocietyDTO): Promise<SocietyDetails> {
        const response = await axios.post(SOCIETIES_URL, payload);
        const dto : SocietyDetailsDTO = response.data;
        return new SocietyDetails(dto);
    }
    async update(id: string, payload: UpdateSocietyDTO): Promise<SocietyDetails> {
        const response = await axios.post(SOCIETY_URL(id), payload);
        const dto : SocietyDetailsDTO = response.data;
        return new SocietyDetails(dto);
    }
}