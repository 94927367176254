import { EquipmentBrandInfos, EquipmentBrandInfosDTO, LuxmeterDetails } from "@/domain";

export class LuxmeterItem {
    public id: string;
    public serialNumber: string;
    public equipmentType: string;
    public equipmentBrand: EquipmentBrandInfos;
    public pvNumber: string;
    public disabled: boolean;

    constructor(parameters: LuxmeterItemDTO) {
        this.id = parameters.id;
        this.serialNumber = parameters.serialNumber;
        this.equipmentType = parameters.equipmentType;
        this.equipmentBrand = new EquipmentBrandInfos(parameters.equipmentBrand);
        this.pvNumber = parameters.pvNumber;
        this.disabled = parameters.disabled;
    }

    static fromDetails(luxmeter: LuxmeterDetails): LuxmeterItem {
        return new LuxmeterItem({
            id: luxmeter.id,
            serialNumber: luxmeter.serialNumber,
            equipmentType: luxmeter.equipmentType,
            equipmentBrand: luxmeter.equipmentBrand,
            pvNumber: luxmeter.pvNumber,
            disabled: luxmeter.disabled
        })
    }
}

export interface LuxmeterItemDTO {
    id: string;
    serialNumber: string;
    equipmentType: string;
    equipmentBrand: EquipmentBrandInfosDTO;
    pvNumber: string;
    disabled: boolean;
}