
import _ from "lodash";
import { Component, InjectReactive, Prop, Vue } from "vue-property-decorator";

import { SERVER_CONFIG } from "@/config/literals";

import { ServerConfig } from "prometheus-synced-ui";
import { RouteParams, UserDetails } from "@/domain";

import ToolbarUser from "@/components/General/ToolbarUser.vue";
import { Route, RouteRecordPublic } from "vue-router";

@Component({
  components: { ToolbarUser },
})
export default class Layout extends Vue {
  @Prop({ required: true })
  user!: UserDetails;

  @InjectReactive(SERVER_CONFIG)
  serverConfig!: ServerConfig;

  get serverName(): string {
    return this.serverConfig.name;
  }

  get breakpoint() {
    return this.$vuetify.breakpoint.mdAndDown;
  }

  get showServer() {
    return (
      ((this.breakpoint && this.mini) ||
        (!this.breakpoint && !this.expanded)) &&
      !(this.breakpoint && this.mini && this.expanded)
    );
  }

  expanded = true;
  mini = true;

  onExpand() {
    if (this.$vuetify.breakpoint.mdAndDown) {
      this.mini = !this.mini;
      this.expanded = false;
    } else {
      this.expanded = !this.expanded;
    }
  }

  get isProd() {
    return window.location.host.indexOf(".gpi.pri") > -1;
  }

  get isDev() {
    return window.location.host.indexOf(".gpidev.pri") > -1;
  }

  get isLocal() {
    return window.location.host.indexOf("localhost") > -1;
  }

  get currentRoute() {
    const result = this.$route.name;
    return result;
  }

  get tabs() {
    const route = this.$route.name;
    const path = this.$route.path;
    const routes = _.flatMap(this.routesGroups, (g) => g.items);

    const menus = _.filter(routes, (m) => {
      return (
        m.to.name == route ||
        (_.includes(path, this.$router.resolve({ name: m.to.name }).href) &&
          m.to.name != "home")
      );
    });

    return _.flatMap(menus, (m) => m.subMenus);
  }

  get showNavigationDrawer(): boolean {
    return this.$route.meta!.showNavigationDrawer!;
  }

  get showAppBar(): boolean {
    return this.$route.meta!.showAppBar!;
  }

  get routesGroups(): { heading: string | null; items: RouteParams[] }[] {
    const routerRoutes = this.$router.getRoutes();
    let result: { heading: string | null; items: RouteParams[] }[] = [];

    const groups = _.chain(routerRoutes)
      .filter((r) => this.showRoute(r))
      .groupBy((r) => r.meta.header)
      .value();

    const subMenus = _.chain(groups)
      .flatMap((value) => value)
      .flatMap((v) => v.meta.subMenus)
      .filter((v) =>
        this.showRoute(_.find(routerRoutes, (r) => r.name == v.to.name)!)
      )
      .value();

    _.forEach(groups, (value, key) => {
      result.push({
        heading: key == "undefined" ? null : key,
        items: _.map(value, (r) => {
          return {
            icon: r.meta.icon,
            label: r.meta.label,
            to: {
              name: r.name!,
            },
            subMenus: _.filter(r.meta.subMenus, s => _.find(subMenus, sub => s.to.name == sub.to.name)),
            exact: r.meta.exact,
            show: _.findIndex(subMenus, (sm) => sm.to.name == r.name) == -1,
          };
        }),
      });
    });
    return result;
  }

  showRoute(route: RouteRecordPublic): boolean {
    return (
      !route.meta.hide(this.serverConfig.type) &&
      route.meta.isAllowed(this.user)
    );
  }
}
