import _ from "lodash";
import { UncertaintyStep, UncertaintyStepDTO } from "@/domain";

export class UncertaintyCriteria {
    public steps: UncertaintyStep[];

    constructor(parameters: UncertaintyCriteriaDTO) {
        this.steps = _.map(parameters.steps, s => new UncertaintyStep(s));
    }
}

export interface UncertaintyCriteriaDTO {
    steps: UncertaintyStepDTO[];
}