import _ from "lodash";
import axios from "axios";

import { ZONES_URL } from "@/config/urls";
import { buildURL } from "@/tools";

import { ZoneInfos, ZoneInfosDTO } from "prometheus-synced-ui";

import { IZoneService, ZoneFilterDTO } from "@/services";

export class ZoneService implements IZoneService {
    async getMany(filter: ZoneFilterDTO): Promise<ZoneInfos[]> {
        const response = await axios.get(buildURL(ZONES_URL, filter));
        const dtos: ZoneInfosDTO[] = response.data;
        return _.map(dtos, d => new ZoneInfos(d));
    }
}