export class CalibrationCriteriaInfos {
    public materialShadeId: string;
    public celerityValue: number;
    public celerityRange: number;
    public minimumCelerity: number;
    public maximumCelerity: number;

    constructor(parameters: CalibrationCriteriaInfosDTO) {
        this.materialShadeId = parameters.materialShadeId;
        this.celerityValue = parameters.celerityValue;
        this.celerityRange = parameters.celerityRange;
        this.minimumCelerity = parameters.minimumCelerity;
        this.maximumCelerity = parameters.maximumCelerity;
    }
}

export interface CalibrationCriteriaInfosDTO {
    materialShadeId: string;
    celerityValue: number;
    celerityRange: number;
    minimumCelerity: number;
    maximumCelerity: number;
}
