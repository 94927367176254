import _ from "lodash";
import { UnimportableData, UnimportableDataDTO, UnreadableLine, UnreadableLineDTO } from "@/domain";

export class FailedSheet {
    public sheetName: string;
    public unreadables: UnreadableLine[];
    public unimportables: UnimportableData[];

    constructor(parameters: FailedSheetDTO) {
        this.sheetName = parameters.sheetName;
        this.unreadables = _.map(parameters.unreadables, u => new UnreadableLine(u));
        this.unimportables = _.map(parameters.unimportables, u => new UnimportableData(u));
    }

    get hasUnreadables(): boolean {
        return this.unreadables.length > 0;
    }

    get hasUnimportables(): boolean {
        return this.unimportables.length > 0;
    }

    get hasFailedData(): boolean {
        return this.hasUnimportables || this.hasUnreadables;
    }
}

export interface FailedSheetDTO {
    sheetName: string;
    unreadables: UnreadableLineDTO[];
    unimportables: UnimportableDataDTO[];
}