export class MaterialShadeDetails {
    public id: string;
    public shadeValue: string;
    public disabled: boolean;

    constructor(parameters: MaterialShadeDetailsDTO) {
        this.id = parameters.id;
        this.shadeValue = parameters.shadeValue;
        this.disabled = parameters.disabled;
    }
}

export interface MaterialShadeDetailsDTO {
    id: string;
    shadeValue: string;
    disabled: boolean;
}