import axios from 'axios';
import _ from 'lodash';

import { CURRENT_USER_URL, USERS_URL, USER_URL } from '@/config/urls';
import { buildURL } from '@/tools';

import { UserDetails, UserDetailsDTO } from '@/domain';
import { UserInfos, UserInfosDTO } from "prometheus-synced-ui";

import { CreateUserDTO, IUserService, UpdateUserDTO, UserFilterDTO } from '@/services/interfaces';


export class UserService implements IUserService {
    async get(id: string): Promise<UserDetails> {
        const response = await axios.get(USER_URL(id));
        const dto: UserDetailsDTO = response.data;
        return new UserDetails(dto);
    }

    async getCurrent(): Promise<UserDetails> {
        const response = await axios.get(CURRENT_USER_URL);
        const dto: UserDetailsDTO = response.data;
        return new UserDetails(dto);
    }

    async getMany(filter: UserFilterDTO): Promise<UserInfos[]> {
        const response = await axios.get(buildURL(USERS_URL, filter));
        const dtos: UserInfosDTO[] = response.data;
        return _.map(dtos, d => new UserInfos(d));
    }

    async create(payload: CreateUserDTO): Promise<UserDetails> {
        const response = await axios.post(USERS_URL, payload);
        const dto: UserDetailsDTO = response.data;
        return new UserDetails(dto);
    }

    async update(id: string, payload: UpdateUserDTO): Promise<UserDetails> {
        const response = await axios.post(USER_URL(id), payload);
        const dto: UserDetailsDTO = response.data;
        return new UserDetails(dto);
    }
}