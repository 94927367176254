import axios from "axios";

import { SYNC_GATEWAY_CREDENTIALS_EXTERNAL_ACCESS_URL } from "@/config/urls";

import { SyncGatewayCredentialsToken, SyncGatewayCredentialsTokenDTO } from "@/domain";

import { ISyncGatewayCredentialsService } from "@/services";

export class SyncGatewayCredentialsService implements ISyncGatewayCredentialsService {
    async getExternalAccess(): Promise<SyncGatewayCredentialsToken> {
        const response = await axios.get(SYNC_GATEWAY_CREDENTIALS_EXTERNAL_ACCESS_URL);
        const result: SyncGatewayCredentialsTokenDTO = response.data;
        return new SyncGatewayCredentialsToken(result);
    }
}