import axios from "axios";

import { SERVER_CONFIGS_URL } from "@/config/urls/";

import { ServerConfig, ServerConfigDTO } from "prometheus-synced-ui";
import { IServerConfigService } from "@/services/interfaces";

export class ServerConfigService implements IServerConfigService {
    async get(): Promise<ServerConfig> {
        const response = await axios.get(SERVER_CONFIGS_URL);
        const dto: ServerConfigDTO = response.data;
        return new ServerConfig(dto);
    }
}