import _ from "lodash";
import axios from "axios";

import { buildURL } from "@/tools";
import { UT_PROBES_URL, UT_PROBE_URL } from "@/config/urls";

import { UtProbeDetails, UtProbeDetailsDTO } from "@/domain";
import { UtProbeItem, UtProbeItemDTO } from "prometheus-synced-ui";
import { CreateUtProbeDTO, UtProbeFilterDTO, IUtProbeService, UpdateUtProbeDTO } from "@/services";

export class UtProbeService implements IUtProbeService {
    async getMany(filter: UtProbeFilterDTO): Promise<UtProbeItem[]> {
        const response = await axios.get(buildURL(UT_PROBES_URL, filter));
        const dtos: UtProbeItemDTO[] = response.data;
        return _.map(dtos, d => new UtProbeItem(d));
    }

    async create(payload: CreateUtProbeDTO): Promise<UtProbeDetails> {
        const response = await axios.post(UT_PROBES_URL, payload);
        const dto: UtProbeDetailsDTO = response.data;
        return new UtProbeDetails(dto);
    }

    async update(id: string, payload: UpdateUtProbeDTO): Promise<UtProbeDetails> {
        const response = await axios.post(UT_PROBE_URL(id), payload);
        const dto: UtProbeDetailsDTO = response.data;
        return new UtProbeDetails(dto);
    }
}