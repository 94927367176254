import { toUnitNumber } from "@/tools";

export class NuclearUnitResume {
    public id: string;
    public number: number;
    public powerPlantName: string;
    public powerPlantTrigram: string;

    constructor(parameters: NuclearUnitResumeDTO) {
        this.id = parameters.id;
        this.number = parameters.number;
        this.powerPlantName = parameters.powerPlantName;
        this.powerPlantTrigram = parameters.powerPlantTrigram;
    }

    public get numberLabel(): string {
        return toUnitNumber(this.number);
    }
}

export interface NuclearUnitResumeDTO {
    id: string;
    number: number;
    powerPlantName: string;
    powerPlantTrigram: string;
}