import axios from "axios";
import _ from "lodash";

import { SENTENCES_URL, SENTENCE_URL } from "@/config/urls";

import { SentenceDetails, SentenceDetailsDTO, SentenceInfos, SentenceInfosDTO } from "@/domain";

import { CreateSentenceDTO, ISentenceService, UpdateSentenceDTO } from "@/services/interfaces";

export class SentenceService implements ISentenceService {
    async get(id: string): Promise<SentenceDetails> {
        const response = await axios.get(SENTENCE_URL(id));
        const dto: SentenceDetailsDTO = response.data;
        return new SentenceDetails(dto);
    }
    
    async getMany(): Promise<SentenceInfos[]> {
        const response = await axios.get(SENTENCES_URL);
        const dtos: SentenceInfosDTO[] = response.data;
        return _.map(dtos, d => new SentenceInfos(d));
    }

    async create(payload: CreateSentenceDTO): Promise<SentenceDetails> {
        const response = await axios.post(SENTENCES_URL, payload);
        const dto: SentenceDetailsDTO = response.data;
        return new SentenceDetails(dto);
    }

    async update(id: string, payload: UpdateSentenceDTO): Promise<SentenceDetails> {
        const response = await axios.post(SENTENCE_URL(id), payload);
        const dto: SentenceDetailsDTO = response.data;
        return new SentenceDetails(dto);
    }

    async remove(id: string): Promise<void> {
        await axios.delete(SENTENCE_URL(id));
    }
}