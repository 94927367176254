import axios from "axios";
import _ from "lodash";

import { buildURL } from "@/tools";
import { EXAM_REPORTS_URL } from "@/config/urls";

import { ExamReportInfos, ExamReportInfosDTO } from "@/domain";
import { ExamReportFilterDTO, IExamReportService } from "@/services/interfaces";

export class ExamReportService implements IExamReportService {
    async getMany(filter: ExamReportFilterDTO): Promise<ExamReportInfos[]> {
        const response = await axios.get(buildURL(EXAM_REPORTS_URL, filter));
        const dtos: ExamReportInfosDTO[] = response.data;
        return _.map(dtos, d => new ExamReportInfos(d));
    }
}