import axios from "axios";
import _ from "lodash";

import { EXCEL_EXAM_REPORTS_URL } from "@/config/urls";

import { IExcelExamReportService } from "@/services/interfaces";
import { ImportWarning, ImportWarningDTO } from "@/domain";

export class ExcelExamReportService implements IExcelExamReportService {
    async import(file: File): Promise<ImportWarning[]> {
        const formData = new FormData();
        formData.append('file', file);

        const response = await axios.post(EXCEL_EXAM_REPORTS_URL, formData);
        const dtos: ImportWarningDTO[] = response.data;
        return _.map(dtos, d => new ImportWarning(d));
    }
}
