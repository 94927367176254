import { DependencyContainer } from 'tsyringe';
import { SERVICES as S } from '@/config/literals';
import {
   DegreaserService, DeveloperService, EquipmentBrandService,
   GenerationService, HygrometerService, IDegreaserService,
   IDeveloperService, IEquipmentBrandService, IGenerationService, IHygrometerService,
   ILuxmeterService, IMaterialShadeService, IPenetrantService, IRoughometerService, ISentenceService,
   ISocietyService, IStepCalibrationBlockService, IThermometerService, IUserService, IUtBoardService,
   IUtGelService, IUtProbeService, IV1CalibrationBlockService, IV2CalibrationBlockService, LuxmeterService,
   MaterialShadeService, V1CalibrationBlockService, PenetrantService, V2CalibrationBlockService, UtBoardService,
   RoughometerService, SentenceService, SocietyService, StepCalibrationBlockService, ThermometerService,
   UserService, UtGelService, UtProbeService, IExamReportService, HydraulicSystemService, HydraulicSystemGroupService,
   IExcelDatabaseService, IOperationalFileService, OperationalFileService, PowerPlantService, IHydraulicSystemService,
   IHydraulicSystemGroupService, ExcelDatabaseService, IProceadureService, ProceadureService,
   IMeasuringRunService, MeasuringRunService, IMockExcelExamReportService, MockExcelExamReportService, ExamReportService,
   INuclearUnitService, NuclearUnitService, IPowerPlantService, IExcelExamReportService, ExcelExamReportService,
   IMeasureTableService, MeasureTableService, IEquipmentService, EquipmentService, IZoneService, ZoneService,
   IServerConfigService, ServerConfigService, IVisitService, VisitService, ISyncGatewayCredentialsService, SyncGatewayCredentialsService, AccountService, IAccountService
} from '@/services';

export function addServices(container: DependencyContainer): void {
   container.registerSingleton<IAccountService>(S.ACCOUNT_SERVICE, AccountService);
   container.registerSingleton<IDegreaserService>(S.DEGREASER_SERVICE, DegreaserService);
   container.registerSingleton<IDeveloperService>(S.DEVELOPER_SERVICE, DeveloperService);
   container.registerSingleton<IEquipmentService>(S.EQUIPMENT_SERVICE, EquipmentService);
   container.registerSingleton<IEquipmentBrandService>(S.EQUIPMENT_BRAND_SERVICE, EquipmentBrandService);
   container.registerSingleton<IExamReportService>(S.EXAM_REPORT_SERVICE, ExamReportService);
   container.registerSingleton<IExcelDatabaseService>(S.EXCEL_DATABASE_SERVICE, ExcelDatabaseService);
   container.registerSingleton<IExcelExamReportService>(S.EXCEL_EXAM_REPORT_SERVICE, ExcelExamReportService);
   container.registerSingleton<IGenerationService>(S.GENERATION_SERVICE, GenerationService);
   container.registerSingleton<IHydraulicSystemService>(S.HYDRAULIC_SYSTEM_SERVICE, HydraulicSystemService);
   container.registerSingleton<IHydraulicSystemGroupService>(S.HYDRAULIC_SYSTEM_GROUP_SERVICE, HydraulicSystemGroupService);
   container.registerSingleton<IHygrometerService>(S.HYGROMETER_SERVICE, HygrometerService);
   container.registerSingleton<ILuxmeterService>(S.LUXMETER_SERVICE, LuxmeterService);
   container.registerSingleton<IMaterialShadeService>(S.MATERIAL_SHADE_SERVICE, MaterialShadeService);
   container.registerSingleton<IMeasureTableService>(S.MEASURE_TABLE_SERVICE, MeasureTableService);
   container.registerSingleton<IMeasuringRunService>(S.MEASURING_RUN_SERVICE, MeasuringRunService);
   container.registerSingleton<IMockExcelExamReportService>(S.MOCK_EXCEL_EXAM_REPORT_SERVICE, MockExcelExamReportService);
   container.registerSingleton<INuclearUnitService>(S.NUCLEAR_UNIT_SERVICE, NuclearUnitService);
   container.registerSingleton<IOperationalFileService>(S.OPERATIONAL_FILE_SERVICE, OperationalFileService);
   container.registerSingleton<IPenetrantService>(S.PENETRANT_SERVICE, PenetrantService);
   container.registerSingleton<IPowerPlantService>(S.POWER_PLANT_SERVICE, PowerPlantService);
   container.registerSingleton<IProceadureService>(S.PROCEADURE_SERVICE, ProceadureService);
   container.registerSingleton<IRoughometerService>(S.ROUGHOMETER_SERVICE, RoughometerService);
   container.registerSingleton<ISentenceService>(S.SENTENCE_SERVICE, SentenceService);
   container.registerSingleton<IServerConfigService>(S.SERVER_CONFIG_SERVICE, ServerConfigService);
   container.registerSingleton<ISocietyService>(S.SOCIETY_SERVICE, SocietyService);
   container.registerSingleton<IStepCalibrationBlockService>(S.STEP_CALIBRATION_BLOCK_SERVICE, StepCalibrationBlockService);
   container.registerSingleton<ISyncGatewayCredentialsService>(S.SYNC_GATEWAY_CREDENTIALS_SERVICE, SyncGatewayCredentialsService);
   container.registerSingleton<IThermometerService>(S.THERMOMETER_SERVICE, ThermometerService)
   container.registerSingleton<IUserService>(S.USER_SERVICE, UserService);
   container.registerSingleton<IUtBoardService>(S.UT_BOARD_SERVICE, UtBoardService);
   container.registerSingleton<IUtGelService>(S.UT_GEL_SERVICE, UtGelService);
   container.registerSingleton<IUtProbeService>(S.UT_PROBE_SERVICE, UtProbeService);
   container.registerSingleton<IV1CalibrationBlockService>(S.V1_CALIBRATION_BLOCK_SERVICE, V1CalibrationBlockService);
   container.registerSingleton<IV2CalibrationBlockService>(S.V2_CALIBRATION_BLOCK_SERVICE, V2CalibrationBlockService);
   container.registerSingleton<IVisitService>(S.VISIT_SERVICE, VisitService);
   container.registerSingleton<IZoneService>(S.ZONE_SERVICE, ZoneService);
}