import { EquipmentBrandInfos, EquipmentBrandInfosDTO, UtGelDetails } from "@/domain";

export class UtGelItem {
    public id: string;
    public serialNumber: string;
    public equipmentType: string;
    public equipmentBrand: EquipmentBrandInfos;
    public disabled: boolean;

    constructor(parameters: UtGelItemDTO) {
        this.id = parameters.id;
        this.serialNumber = parameters.serialNumber;
        this.equipmentType = parameters.equipmentType;
        this.equipmentBrand = new EquipmentBrandInfos(parameters.equipmentBrand);
        this.disabled = parameters.disabled;
    }

    static fromDetails(gel: UtGelDetails): UtGelItem {
        return new UtGelItem({
            id: gel.id,
            serialNumber: gel.serialNumber,
            equipmentType: gel.equipmentType,
            equipmentBrand: gel.equipmentBrand,
            disabled: gel.disabled
        });
    }
}

export interface UtGelItemDTO {
    id: string;
    serialNumber: string;
    equipmentType: string;
    equipmentBrand: EquipmentBrandInfosDTO;
    disabled: boolean;
}