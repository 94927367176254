import { HydraulicSystemGroupInfos, HydraulicSystemGroupInfosDTO, } from "@/domain";
import { NuclearUnitInfos, NuclearUnitInfosDTO } from "prometheus-synced-ui";

export class HydraulicSystemDetails {
    public id: string;
    public name: string;
    public trigram: string;
    public group: HydraulicSystemGroupInfos;
    public nuclearUnit: NuclearUnitInfos;
    public disabled: boolean;

    constructor(parameters: HydraulicSystemDetailsDTO) {
        this.id = parameters.id;
        this.name = parameters.name;
        this.trigram = parameters.trigram;
        this.group = new HydraulicSystemGroupInfos(parameters.group);
        this.nuclearUnit = new NuclearUnitInfos(parameters.nuclearUnit);
        this.disabled = parameters.disabled;
    }
}

export interface HydraulicSystemDetailsDTO {
    id: string;
    name: string;
    trigram: string;
    group: HydraulicSystemGroupInfosDTO;
    nuclearUnit: NuclearUnitInfosDTO;
    disabled: boolean;
}