import _ from "lodash";
import { NuclearUnitInfos, NuclearUnitInfosDTO, PowerPlantInfos } from "prometheus-synced-ui";

export class PowerPlantDetails {
    public id: string;
    public name: string;
    public trigram: string;
    public nuclearUnits: NuclearUnitInfos[];
    public disabled: boolean;

    constructor(parameters: PowerPlantDetailsDTO) {
        this.id = parameters.id;
        this.name = parameters.name;
        this.trigram = parameters.trigram;
        this.nuclearUnits = _.map(parameters.nuclearUnits, n => new NuclearUnitInfos(n));
        this.disabled = parameters.disabled;
    }

    public toInfos(): PowerPlantInfos{
        return new PowerPlantInfos({
            id: this.id,
            name: this.name,
            trigram: this.trigram,
            disabled: this.disabled
        });
    }
}

export interface PowerPlantDetailsDTO {
    id: string;
    name: string;
    trigram: string;
    nuclearUnits: NuclearUnitInfosDTO[];
    disabled: boolean;
}