import { MaterialShadeInfos, MaterialShadeInfosDTO, V2CalibrationBlockDetails } from "@/domain";

export class V2CalibrationBlockItem {
    public id: string;
    public serialNumber: string;
    public dimensionValue: number;
    public materialShade: MaterialShadeInfos;
    public celerity: number;
    public pvNumber: string;
    public disabled: boolean;

    constructor(parameters: V2CalibrationBlockItemDTO) {
        this.id = parameters.id;
        this.serialNumber = parameters.serialNumber;
        this.dimensionValue = parameters.dimensionValue;
        this.materialShade = new MaterialShadeInfos(parameters.materialShade);
        this.celerity = parameters.celerity;
        this.pvNumber = parameters.pvNumber;
        this.disabled = parameters.disabled;
    }

    static fromDetails(block: V2CalibrationBlockDetails): V2CalibrationBlockItem {
        return new V2CalibrationBlockItem({
            id: block.id,
            serialNumber: block.serialNumber,
            celerity: block.celerity,
            materialShade: block.materialShade,
            dimensionValue: block.dimensionValue,
            pvNumber: block.pvNumber,
            disabled: block.disabled
        });
    }
}

export interface V2CalibrationBlockItemDTO {
    id: string;
    serialNumber: string;
    dimensionValue: number;
    materialShade: MaterialShadeInfosDTO;
    celerity: number;
    pvNumber: string;
    disabled: boolean;
}