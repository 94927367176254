export const DEGREASER_SERVICE = "DegreaserService";
export const DEVELOPER_SERVICE = "DeveloperService";
export const ACCOUNT_SERVICE = "AccountService";
export const EQUIPMENT_SERVICE = "EquipmentService";
export const EQUIPMENT_BRAND_SERVICE = "EquipmentBrandService"
export const EXAM_REPORT_SERVICE = "ExamReportService";
export const EXCEL_DATABASE_SERVICE = "ExcelDatabaseService";
export const MOCK_EXCEL_EXAM_REPORT_SERVICE = "MockExcelExamReportService";
export const EXCEL_EXAM_REPORT_SERVICE = "ExcelExamReportService";
export const GENERATION_SERVICE = "GenerationService";
export const HYDRAULIC_SYSTEM_SERVICE = "HydraulicSystemService";
export const HYDRAULIC_SYSTEM_GROUP_SERVICE = "HydraulicSystemGroupService";
export const HYGROMETER_SERVICE = "HygrometerService";
export const LUXMETER_SERVICE = "LuxmeterService";
export const MATERIAL_SHADE_SERVICE = "MaterialShadeService";
export const MEASURE_TABLE_SERVICE = "MeasureTableService";
export const MEASURING_RUN_SERVICE = "MeasuringRunService";
export const NUCLEAR_UNIT_SERVICE = "NuclearUnitService";
export const OPERATIONAL_FILE_SERVICE = "OperationalFileService";
export const PDF_FILE_SERVICE = "PdfFileService";
export const PENETRANT_SERVICE = "PenetrantService";
export const POWER_PLANT_SERVICE = "PowerPlantService";
export const PROCEADURE_SERVICE = "ProceadureService";
export const ROUGHOMETER_SERVICE = "RoughometerService";
export const SENTENCE_SERVICE = "SentenceService";
export const SERVER_CONFIG_SERVICE = "ServerConfigService";
export const SOCIETY_SERVICE = "SocietyService";
export const STEP_CALIBRATION_BLOCK_SERVICE = "StepCalibrationBlockService";
export const SYNC_GATEWAY_CREDENTIALS_SERVICE = "SyncGatewayCredentialsService";
export const THERMOMETER_SERVICE = "ThermometerService";
export const USER_SERVICE = "UserService";
export const UT_BOARD_SERVICE = "UtBoardService";
export const UT_GEL_SERVICE = "UtGelService";
export const UT_PROBE_SERVICE = "UtProbeService";
export const V1_CALIBRATION_BLOCK_SERVICE = "V1CalibrationBlockService";
export const V2_CALIBRATION_BLOCK_SERVICE = "V2CalibrationBlockService";
export const VISIT_SERVICE = "VisitService";
export const ZONE_SERVICE = "ZoneService";