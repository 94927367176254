import { MaterialShadeDetails } from "@/domain";

export class MaterialShadeInfos {
    public id: string;
    public shadeValue: string;
     public disabled: boolean;

    constructor(parameters: MaterialShadeInfosDTO) {
        this.id = parameters.id;
        this.shadeValue = parameters.shadeValue;
        this.disabled = parameters.disabled;
    }

    static fromDetails(shade: MaterialShadeDetails): MaterialShadeInfos {
        return new MaterialShadeInfos({
            id: shade.id,
            shadeValue: shade.shadeValue,
            disabled: shade.disabled
        })
    }
}

export interface MaterialShadeInfosDTO {
    id: string;
    shadeValue: string;
    disabled: boolean;
}