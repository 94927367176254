
import axios from "axios";

import {
  Component,
  Inject,
  ProvideReactive,
  Vue,
} from "vue-property-decorator";
import lightFormat from "date-fns/esm/lightFormat/index.js";
import { DependencyContainer } from "tsyringe";

import {
  PROVIDER,
  USER,
  SERVICES as S,
  SERVER_CONFIG,
} from "@/config/literals";

import { ServerConfig, ServerType } from "prometheus-synced-ui";
import { CLOUD_FEATURE, LITE_FEATURE, UserDetails } from "@/domain";

import Loading from "@/views/Loading.vue";
import LoggingEdf from "@/views/LoggingEDF.vue";
import LoggingAzureAd from "@/views/LoggingAzureAD.vue";
import Layout from "@/Layout.vue";
import { Feature } from "vue-feature-flags";

import { IServerConfigService, IUserService } from "@/services";

@Component({
  components: {
    Layout,
    Loading,
    LoggingEdf,
    LoggingAzureAd,
    Feature,
  },
  data: () => {
    return {
      ServerType,
      CLOUD_FEATURE,
      LITE_FEATURE,
    };
  },
})
export default class App extends Vue {
  error: boolean = false;
  message: string = "";

  fetching: boolean = true;

  @ProvideReactive(USER)
  user: UserDetails | null = null;

  @ProvideReactive(SERVER_CONFIG)
  serverConfig: ServerConfig | null = null;

  @Inject(PROVIDER)
  container!: DependencyContainer;

  get userService() {
    return this.container.resolve<IUserService>(S.USER_SERVICE);
  }

  get serverConfigService() {
    return this.container.resolve<IServerConfigService>(
      S.SERVER_CONFIG_SERVICE
    );
  }

  async setupAxios() {
    let self = this;
    axios.interceptors.response.use(undefined, async function (error) {
      console.log(error.response);
      switch (parseInt(error.response.status)) {
        case 400:
          self.message = "Saisie incorrecte";
          break;
        case 401:
          self.message = "Vous êtes déconnecté";
          break;
        case 403:
          self.message =
            "Vous n'avez pas les droits pour effectuer cette action";
          break;
        case 404:
          self.message =
            "Vous tentez d'accéder à une ressource qui n'existe pas";
          break;
        case 409:
          if (error.request.responseType === "blob") {
            self.message = await error.response.data.text();
          } else {
            self.message = error.response.data;
          }
          break;
        case 449:
          if (error.response.data) {
            self.message = error.response.data;
          } else {
            self.message;
            ("Un problème est survenu, rechargez la page et réessayez");
          }
          break;
        case 500:
          self.message = "Ceci est un bug";
          break;
        case 502:
          self.message = "Serveur down";
          break;
        case 503:
          self.message = "Serveur down";
          break;
        default:
          self.message = "Erreur non connue";
          break;
      }
      self.error = true;
      throw error;
    });
  }

  async fetch() {
    this.fetching = true;
    try {
      this.serverConfig = await this.serverConfigService.get();
      this.user = await this.userService.getCurrent();
    } finally {
      this.fetching = false;
    }
  }

  mounted() {
    Date.prototype.toJSON = function (value: any) {
      return lightFormat(this, "yyyy-MM-dd");
    };

    this.setupAxios();
    this.fetch();
  }
}
