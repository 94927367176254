import axios from "axios";
import _ from "lodash";

import { buildURL } from "@/tools";
import { EQUIPMENT_BRANDS_URL, EQUIPMENT_BRAND_URL } from "@/config/urls";

import { EquipmentBrandDetails, EquipmentBrandDetailsDTO, EquipmentBrandInfos, EquipmentBrandInfosDTO } from "@/domain";
import { CreateEquipmentBrandDTO, EquipmentBrandFilterDTO, IEquipmentBrandService, UpdateEquipmentBrandDTO } from "@/services";

export class EquipmentBrandService implements IEquipmentBrandService {
    async getMany(filter: EquipmentBrandFilterDTO): Promise<EquipmentBrandInfos[]> {
        const response = await axios.get(buildURL(EQUIPMENT_BRANDS_URL, filter));
        const dtos: EquipmentBrandInfosDTO[] = response.data;
        return _.map(dtos, d => new EquipmentBrandInfos(d));
    }

    async create(payload: CreateEquipmentBrandDTO): Promise<EquipmentBrandDetails> {
        const response = await axios.post(EQUIPMENT_BRANDS_URL, payload);
        const dto: EquipmentBrandDetailsDTO = response.data;
        return new EquipmentBrandDetails(dto);
    }

    async update(id: string, payload: UpdateEquipmentBrandDTO): Promise<EquipmentBrandDetails> {
        const response = await axios.post(EQUIPMENT_BRAND_URL(id), payload);
        const dto: EquipmentBrandDetailsDTO = response.data;
        return new EquipmentBrandDetails(dto);
    }
}