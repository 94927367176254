export class SentenceDetails{
    public id: string;
    public number: number | null;
    public method: string;
    public target: string;
    public sentenceValue: string;

    constructor(parameters: SentenceDetailsDTO) {
        this.id = parameters.id;
        this.number = parameters.number;
        this.method = parameters.method;
        this.target = parameters.target;
        this.sentenceValue = parameters.sentenceValue;
    }
}

export interface SentenceDetailsDTO{
    id: string;
    number: number | null;
    method: string;
    target: string;
    sentenceValue: string;
}