export class HydraulicSystemTree {
    public id: string;
    public name: string;
    public trigram: string;
    public disabled: boolean;

    constructor(parameters: HydraulicSystemTreeDTO) {
        this.id = parameters.id;
        this.name = parameters.name;
        this.trigram = parameters.trigram;
        this.disabled = parameters.disabled;
    }
}

export interface HydraulicSystemTreeDTO {
    id: string;
    name: string;
    trigram: string;
    disabled: boolean;
}