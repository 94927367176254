
import { Component, Inject, Vue } from "vue-property-decorator";
import { DependencyContainer } from "tsyringe";

import { PROVIDER, SERVICES as S } from "@/config/literals";

import Logging from "@/views/Logging.vue";

import { IAccountService } from "@/services";

@Component({
  components: {
    Logging,
  },
})
export default class LoggingEDF extends Vue {
  @Inject(PROVIDER)
  container!: DependencyContainer;

  get accountService() {
    return this.container.resolve<IAccountService>(S.ACCOUNT_SERVICE);
  }

  badge: string | null = null;

  async login() {
    await this.accountService.loginEDF({
      badgeNumber: this.badge!,
      persistent: false,
    });
    this.$emit("login");
  }
}
