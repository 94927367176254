import { EquipmentBrandInfos, EquipmentBrandInfosDTO } from "@/domain";

export class HygrometerDetails {
    public id: string;
    public serialNumber: string;
    public equipmentType: string;
    public equipmentBrand: EquipmentBrandInfos;
    public pvNumber: string;
    public disabled: boolean;

    constructor(parameters: HygrometerDetailsDTO) {
        this.id = parameters.id;
        this.serialNumber = parameters.serialNumber;
        this.equipmentType = parameters.equipmentType;
        this.equipmentBrand = new EquipmentBrandInfos(parameters.equipmentBrand);
        this.pvNumber = parameters.pvNumber;
        this.disabled = parameters.disabled;

    }
}

export interface HygrometerDetailsDTO {
    id: string;
    serialNumber: string;
    equipmentType: string;
    equipmentBrand: EquipmentBrandInfosDTO;
    pvNumber: string;
    disabled: boolean;
}