export class SocietyDetails {
    public id: string;
    public name: string;
    public disabled: boolean;

    constructor(parameters: SocietyDetailsDTO) {
        this.id = parameters.id;
        this.name = parameters.name;
        this.disabled = parameters.disabled;
    }
}

export interface SocietyDetailsDTO {
    id: string;
    name: string;
    disabled: boolean;
}