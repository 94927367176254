import VueRouter, { RouteConfig } from "vue-router";

import { ServerType } from "prometheus-synced-ui";
import { CLOUD_FEATURE, LITE_FEATURE, UserDetails } from "@/domain";

import Home from "@/views/Home.vue";

export const routes: Array<RouteConfig> = [
    {
        path: "/",
        name: "home",
        component: Home,
        meta: {
            icon: "mdi-blur",
            label: "Accueil",
            subMenus: [],
            isAllowed: (user: UserDetails) => true,
            showNavigationDrawer: true,
            showAppBar: true,
            hide: (serverType: ServerType) => false,
            exact: true
        }
    },
    {
        path: "/users-menu/users",
        name: "users",
        component: () => import("@/views/DatabaseHandling/Users.vue"),
        meta: {
            icon: "mdi-account-outline",
            label: "Utilisateurs",
            subMenus: [],
            header: "Gestion BDD",
            isAllowed: (user: UserDetails) => user.isAdministrator,
            showNavigationDrawer: true,
            showAppBar: true,
            hide: (serverType: ServerType) => false,
        }
    },
    {
        path: "/users-menu/societies",
        name: "societies",
        component: () => import("@/views/DatabaseHandling/Societies.vue"),
        meta: {
            icon: "mdi-office-building",
            label: "Sociétés",
            subMenus: [],
            header: "Gestion BDD",
            isAllowed: (user: UserDetails) => user.isAdministrator,
            showNavigationDrawer: true,
            showAppBar: true,
            hide: (serverType: ServerType) => false,
        }
    },
    {
        path: "/users-menu",
        name: "users-menu",
        redirect: { name: "users" },
        meta: {
            icon: "mdi-account-outline",
            label: "Utilisateurs et sociétés",
            subMenus: [
                {
                    label: "Utilisateurs",
                    icon: "mdi-account-outline",
                    to: { name: "users" }
                },
                {
                    label: "Sociétés",
                    icon: "mdi-office-building",
                    to: { name: "societies" }
                }
            ],
            header: "Gestion BDD",
            isAllowed: (user: UserDetails) => user.isAdministrator,
            showNavigationDrawer: true,
            showAppBar: true,
            hide: (serverType: ServerType) => false,
        }
    },
    {
        path: "/equipments-menu/equipments",
        name: "equipments",
        component: () => import("@/views/DatabaseHandling/Equipments/Equipments.vue"),
        meta: {
            icon: "mdi-account-outline",
            label: "Equipements",
            subMenus: [],
            header: "Gestion BDD",
            isAllowed: (user: UserDetails) => user.canAccessDb,
            showNavigationDrawer: true,
            showAppBar: true,
            hide: (serverType: ServerType) => false,
        }
    },
    {
        path: "/equipments-menu/equipment-brands",
        name: "equipment-brands",
        component: () => import("@/views/DatabaseHandling/EquipmentBrands.vue"),
        meta: {
            icon: "mdi-office-building",
            label: "Marques",
            subMenus: [],
            header: "Gestion BDD",
            isAllowed: (user: UserDetails) => user.canAccessDb,
            showNavigationDrawer: true,
            showAppBar: true,
            hide: (serverType: ServerType) => false,
        }
    },
    {
        path: "/equipments-menu",
        name: "equipments-menu",
        redirect: { name: "equipments" },
        meta: {
            icon: "mdi-hammer-wrench",
            label: "Matériel",
            subMenus: [
                {
                    label: "Equipements",
                    icon: "mdi-hammer-wrench",
                    to: { name: "equipments" }
                },
                {
                    label: "Marques",
                    icon: "mdi-tag-multiple",
                    to: { name: "equipment-brands" }
                }
            ],
            header: "Gestion BDD",
            isAllowed: (user: UserDetails) => user.canAccessDb,
            showNavigationDrawer: true,
            showAppBar: true,
            hide: (serverType: ServerType) => false,
        }
    },
    {
        path: "/localisations-menu/power-plant-nuclear-units",
        name: "power-plant-nuclear-units",
        component: () => import("@/views/DatabaseHandling/Localisation/PowerPlantsNuclearUnits.vue"),
        meta: {
            icon: "mdi-account-outline",
            label: "Sites/Tranches",
            subMenus: [],
            header: "Gestion BDD",
            isAllowed: (user: UserDetails) => user.canAccessDb,
            showNavigationDrawer: true,
            showAppBar: true,
            hide: (serverType: ServerType) => false,
        }
    },
    {
        path: "/localisations-menu/groups",
        name: "groups",
        component: () => import("@/views/DatabaseHandling/Localisation/Groups.vue"),
        meta: {
            icon: "mdi-pipe",
            label: "Groupes",
            subMenus: [],
            header: "Gestion BDD",
            isAllowed: (user: UserDetails) => user.canAccessDb,
            showNavigationDrawer: true,
            showAppBar: true,
            hide: (serverType: ServerType) => false,
        }
    },
    {
        path: "/localisations-menu/systems",
        name: "systems",
        component: () => import("@/views/DatabaseHandling/Localisation/Systems.vue"),
        meta: {
            icon: "mdi-pipe",
            label: "Systèmes",
            subMenus: [],
            header: "Gestion BDD",
            isAllowed: (user: UserDetails) => user.canAccessDb,
            showNavigationDrawer: true,
            showAppBar: true,
            hide: (serverType: ServerType) => false,
        }
    },
    {
        path: "/localisations-menu/generations",
        name: "generations",
        component: () => import("@/views/DatabaseHandling/Localisation/Generations.vue"),
        meta: {
            icon: "mdi-office-building",
            label: "Générations",
            subMenus: [],
            header: "Gestion BDD",
            isAllowed: (user: UserDetails) => user.canAccessDb,
            showNavigationDrawer: true,
            showAppBar: true,
            hide: (serverType: ServerType) => false,
        }
    },
    {
        path: "/localisations-menu",
        name: "localisations-menu",
        redirect: { name: "power-plant-nuclear-units" },
        meta: {
            icon: "mdi-map-outline",
            label: "Zones",
            subMenus: [
                {
                    label: "Sites/Tranches",
                    icon: "mdi-factory",
                    to: { name: "power-plant-nuclear-units" }
                },
                {
                    label: "Générations",
                    icon: "mdi-animation-outline",
                    to: { name: "generations" }
                },
                {
                    label: "Systèmes",
                    icon: "mdi-pipe",
                    to: { name: "systems" }
                },
                {
                    label: "Groupes",
                    icon: "mdi-group",
                    to: { name: "groups" }
                }
            ],
            header: "Gestion BDD",
            isAllowed: (user: UserDetails) => user.canAccessDb,
            showNavigationDrawer: true,
            showAppBar: true,
            hide: (serverType: ServerType) => false,
        }
    },
    {
        path: "/sentences",
        name: "sentences",
        component: () => import("@/views/DatabaseHandling/Sentences.vue"),
        meta: {
            icon: "mdi-dots-horizontal",
            label: "Phrases",
            subMenus: [],
            header: "Gestion BDD",
            isAllowed: (user: UserDetails) => user.canAccessDb,
            showNavigationDrawer: true,
            showAppBar: true,
            hide: (serverType: ServerType) => false
        }
    },
    {
        path: "/excel-bdd",
        name: "excel-bdd",
        component: () => import("@/views/DatabaseHandling/ExcelBDD.vue"),
        meta: {
            icon: "mdi-file-move-outline",
            label: "Import/Export Excel BDD",
            subMenus: [],
            header: "Gestion BDD",
            isAllowed: (user: UserDetails) => user.canAccessDb,
            showNavigationDrawer: true,
            showAppBar: true,
            hide: (serverType: ServerType) => false
        }
    },
    {
        path: "/proceadures",
        name: "proceadures",
        component: () => import("@/views/DatabaseHandling/Proceadures.vue"),
        meta: {
            icon: "mdi-clipboard-list-outline",
            label: "Procédures",
            subMenus: [],
            header: "Gestion BDD",
            isAllowed: (user: UserDetails) => user.canAccessDb,
            showNavigationDrawer: true,
            showAppBar: true,
            hide: (serverType: ServerType) => false
        }
    },
    {
        path: "/excel-import-re",
        name: "excel-import-re",
        component: () => import("@/views/ExamReportsHandling/ExamReport/ExcelImportRe.vue"),
        meta: {
            icon: "mdi-application-import",
            label: "Import",
            subMenus: [],
            header: "Gestion RE",
            isAllowed: (user: UserDetails) => user.canAccessExamReport,
            showNavigationDrawer: true,
            showAppBar: true,
            hide: (serverType: ServerType) => !LITE_FEATURE.isEnabled(serverType)
        }
    },
    {
        path: "/dashboard-re",
        name: "dashboard-re",
        component: () => import("@/views/ExamReportsHandling/ExamReport/ExamReportDashboard.vue"),
        meta: {
            icon: "mdi-chart-bell-curve",
            label: "Dashboard RE",
            subMenus: [],
            header: "Gestion RE",
            isAllowed: (user: UserDetails) => user.canAccessExamReport,
            showNavigationDrawer: true,
            showAppBar: true,
            hide: (serverType: ServerType) => false
        }
    },
    {
        path: "/exam-report-edit/:runId?",
        name: "exam-report-edit",
        component: () => import("@/views/ExamReportsHandling/ExamReport/ExamReportEditing.vue"),
        meta: {
            defaultParams: (params: any) => ({
                runId: params.runId || undefined
            }),
            icon: "mdi-pencil-outline",
            label: "Édition RE",
            subMenus: [],
            header: "Gestion RE",
            isAllowed: (user: UserDetails) => user.canAccessExamReport,
            showNavigationDrawer: true,
            showAppBar: true,
            hide: (serverType: ServerType) => !LITE_FEATURE.isEnabled(serverType)
        }
    },
    {
        path: "/historic-menu",
        name: "historic-menu",
        redirect: { name: "historic-dashboard" },
        meta: {
            icon: "mdi-timeline-clock",
            label: "Gestion Historique",
            subMenus: [
                {
                    label: "Dashboard",
                    icon: "mdi-view-dashboard",
                    to: { name: "historic-dashboard" }
                },
                {
                    label: "Comparaison",
                    icon: "mdi-magnify",
                    to: { name: "historic-comparaison" }
                },
                {
                    label: "Import",
                    icon: "mdi-table-arrow-right",
                    to: { name: "historic-import" },
                }
            ],
            header: "Gestion RE",
            isAllowed: (user: UserDetails) => user.canAccessExamReport,
            showNavigationDrawer: true,
            showAppBar: true,
            hide: (serverType: ServerType) => false,
        }
    },
    {
        path: "/historic-menu/dashboard",
        name: "historic-dashboard",
        component: () => import("@/views/ExamReportsHandling/Historic/HistoricDashboard.vue"),
        meta: {
            icon: "mdi-view-dashboard",
            label: "Dashboard Historique",
            subMenus: [],
            header: "Gestion RE",
            isAllowed: (user: UserDetails) => user.canAccessExamReport,
            showNavigationDrawer: true,
            showAppBar: true,
            hide: (serverType: ServerType) => false,
        }
    },
    {
        path: "/historic-menu/comparaison/:powerPlantId?/:nuclearUnitId?/:systemId?/:equipmentId?/:zoneId?/:firstTableId?/:secondTableId?/:fromDashboard?",
        name: "historic-comparaison",
        component: () => import("@/views/ExamReportsHandling/Historic/HistoricComparison.vue"),
        meta: {
            defaultParams: (params: any) => ({
                powerPlantId: params.powerPlantId || undefined,
                nuclearUnitId: params.nuclearUnitId || undefined,
                systemId: params.systemId || undefined,
                equipmentId: params.equipmentId || undefined,
                zoneId: params.zoneId || undefined,
                firstTableId: params.firstTableId || undefined,
                secondTableId: params.secondTableId || undefined,
            }),
            icon: "mdi-magnify",
            label: "Comparaison Historique",
            subMenus: [],
            header: "Gestion RE",
            isAllowed: (user: UserDetails) => user.canAccessExamReport,
            showNavigationDrawer: true,
            showAppBar: true,
            hide: (serverType: ServerType) => false,
        }
    },
    {
        path: "/historic-menu/import",
        name: "historic-import",
        component: () => import("@/views/ExamReportsHandling/Historic/HistoricImport.vue"),
        meta: {
            icon: "mdi-table-arrow-right",
            label: "Import",
            subMenus: [],
            header: "Gestion RE",
            isAllowed: (user: UserDetails) => user.canAccessExamReport,
            showNavigationDrawer: true,
            showAppBar: true,
            hide: (serverType: ServerType) => !LITE_FEATURE.isEnabled(serverType),
        }
    },
    {
        path: "/transactions",
        name: "transactions",
        component: () => import("@/views/ExamReportsHandling/ExamReport/ExamReportTransactions.vue"),
        meta: {
            icon: "mdi-vector-polyline",
            label: "Transactions",
            subMenus: [],
            header: "Gestion RE",
            isAllowed: (user: UserDetails) => user.canAccessExamReport,
            showNavigationDrawer: true,
            showAppBar: true,
            hide: (serverType: ServerType) => false
        }
    },
    {
        path: "/su/debug",
        name: "debug",
        component: () => import("@/views/SuperAdmin/Debug.vue"),
        meta: {
            icon: "mdi-bug-outline",
            label: "Debug",
            subMenus: [],
            header: "Super Admin",
            isAllowed: (user: UserDetails) => user.isSuperAdministrator,
            showNavigationDrawer: true,
            showAppBar: true,
            hide: (serverType: ServerType) => false,
        }
    },
    {
        path: "/su/access-token",
        name: "access-token",
        component: () => import("@/views/SuperAdmin/TabletAccessToken.vue"),
        meta: {
            icon: "mdi-cloud-key",
            label: "Tokens d'accès",
            subMenus: [],
            header: "Super Admin",
            isAllowed: (user: UserDetails) => user.isSuperAdministrator,
            showNavigationDrawer: true,
            showAppBar: true,
            hide: (serverType: ServerType) => !CLOUD_FEATURE.isEnabled(serverType),
        }
    },
];

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
});

export default router