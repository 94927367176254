export enum MeasuringRunStatus {
    unknown = 0,
    created = 1,
    assigned = 2,
    launched = 3,
    terminated = 4,
    canceledByAnalyst = 5,
}
interface MeasuringRunStatusDisplay {
    label: string;
    color: string;
    icon: string;
}
const statuses: Map<MeasuringRunStatus, MeasuringRunStatusDisplay> = new Map<MeasuringRunStatus, MeasuringRunStatusDisplay>();

statuses.set(MeasuringRunStatus.created, {
    label: "Créé",
    color: "grey",
    icon: "mdi-folder-plus-outline",
});

statuses.set(MeasuringRunStatus.assigned, {
    icon: "mdi-account-check-outline",
    label: "Assigné",
    color: "blue darken-4",
});

statuses.set(MeasuringRunStatus.launched, {
    icon: "mdi-rocket-launch-outline",
    label: "Lancé",
    color: "orange",
});

statuses.set(MeasuringRunStatus.terminated, {
    icon: "mdi-check-circle-outline",
    label: "Terminé",
    color: "green",
});

statuses.set(MeasuringRunStatus.canceledByAnalyst, {
    icon: "mdi-close-circle-outline",
    label: "Annulé par Analyste",
    color: "error",
});

export {statuses as MEASURING_RUN_STATUSES_DISPLAY};


