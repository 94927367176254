import axios from "axios";
import _ from "lodash";

import { buildURL } from "@/tools";
import { DEGREASER_URL, DEGREASERS_URL } from "@/config/urls";

import { DegreaserDetails, DegreaserDetailsDTO, DegreaserItem, DegreaserItemDTO } from "@/domain";
import { CreateDegreaserDTO, DegreaserFilterDTO, IDegreaserService, UpdateDegreaderDTO } from "@/services/interfaces";

export class DegreaserService implements IDegreaserService {
    async getMany(filter: DegreaserFilterDTO): Promise<DegreaserItem[]> {
        const response = await axios.get(buildURL(DEGREASERS_URL, filter));
        const dtos: DegreaserItemDTO[] = response.data;
        return _.map(dtos, d => new DegreaserItem(d));
    }

    async create(payload: CreateDegreaserDTO): Promise<DegreaserDetails> {
        const response = await axios.post(DEGREASERS_URL, payload);
        const dto: DegreaserDetailsDTO = response.data;
        return new DegreaserDetails(dto);
    }

    async update(id: string, payload: UpdateDegreaderDTO): Promise<DegreaserDetails> {
        const response = await axios.post(DEGREASER_URL(id), payload);
        const dto: DegreaserDetailsDTO = response.data;
        return new DegreaserDetails(dto);
    }
}