import axios from "axios";
import _ from "lodash";

import { buildURL } from "@/tools";
import { VISITS_URL } from "@/config/urls";

import { VisitInfos, VisitInfosDTO } from "prometheus-synced-ui";

import { IVisitService, VisitFilterDTO } from "@/services/interfaces";

export class VisitService implements IVisitService {

    async getMany(filter: VisitFilterDTO): Promise<VisitInfos[]> {
        const response = await axios.get(buildURL(VISITS_URL, filter));
        const dtos: VisitInfosDTO[] = response.data;
        return _.map(dtos, d => new VisitInfos(d));
    }
}