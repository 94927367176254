import _ from "lodash";
import axios from "axios";

import {
    MEASURING_RUNS_URL, MEASURING_RUN_ASSIGN_URL, MEASURING_RUN_RESUME_URL,
    MEASURING_RUN_STATUS_URL, MEASURING_RUN_SUPER_ADMIN_URL, MEASURING_RUN_URL
} from "@/config/urls";

import { buildURL } from "@/tools";

import {
    MeasuringRunDetails, MeasuringRunDetailsDTO, MeasuringRunInfos,
    MeasuringRunInfosDTO, MeasuringRunResume, MeasuringRunResumeDTO
} from "@/domain";

import {
    AssignControllersDTO, IMeasuringRunService, MeasuringRunFilterDTO,
    UpdateMeasuringRunDTO, UpdateStatusMeasuringRunDTO
} from "@/services/interfaces";

export class MeasuringRunService implements IMeasuringRunService {
    async get(id: string): Promise<MeasuringRunDetails> {
        const response = await axios.get(MEASURING_RUN_URL(id));
        const dto: MeasuringRunDetailsDTO = response.data;
        return new MeasuringRunDetails(dto);
    }
    async getResume(): Promise<MeasuringRunResume[]> {
        const response = await axios.get(MEASURING_RUN_RESUME_URL);
        const dtos: MeasuringRunResumeDTO[] = response.data;
        return _.map(dtos, r => new MeasuringRunResume(r));
    }
    async getMany(filter: MeasuringRunFilterDTO): Promise<MeasuringRunInfos[]> {
        const response = await axios.get(buildURL(MEASURING_RUNS_URL, filter));
        const dtos: MeasuringRunInfosDTO[] = response.data;
        return _.map(dtos, d => new MeasuringRunInfos(d));
    }
    async update(id: string, payload: UpdateMeasuringRunDTO): Promise<MeasuringRunDetails> {
        const response = await axios.post(MEASURING_RUN_URL(id), payload);
        const dto: MeasuringRunDetailsDTO = response.data;
        return new MeasuringRunDetails(dto);
    }
    async updateStatus(id: string, payload: UpdateStatusMeasuringRunDTO): Promise<MeasuringRunDetails> {
        const response = await axios.post(MEASURING_RUN_STATUS_URL(id), payload);
        const dto: MeasuringRunDetailsDTO = response.data;
        return new MeasuringRunDetails(dto);
    }
    async assignManyRuns(payloads: AssignControllersDTO[]): Promise<MeasuringRunInfos[]> {
        const response = await axios.post(MEASURING_RUN_ASSIGN_URL, payloads);
        const dtos: MeasuringRunInfosDTO[] = response.data;
        return _.map(dtos, d => new MeasuringRunInfos(d));
    }
    async remove(id: string): Promise<void> {
        await axios.delete(MEASURING_RUN_URL(id));
    }
    async superAdminRemove(id: string): Promise<void> {
        await axios.delete(MEASURING_RUN_SUPER_ADMIN_URL(id));
    }
}