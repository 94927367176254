import axios from "axios";
import _ from "lodash";

import { DEVELOPERS_URL, DEVELOPER_URL } from "@/config/urls";
import { buildURL } from "@/tools";

import { DeveloperDetails, DeveloperDetailsDTO, DeveloperItem, DeveloperItemDTO } from "@/domain";
import { CreateDeveloperDTO, DeveloperFilterDTO, IDeveloperService, UpdateDeveloperDTO } from "@/services";

export class DeveloperService implements IDeveloperService {
    async getMany(filter: DeveloperFilterDTO): Promise<DeveloperItem[]> {
        const response = await axios.get(buildURL(DEVELOPERS_URL, filter));
        const dtos: DeveloperItemDTO[] = response.data;
        return _.map(dtos, d => new DeveloperItem(d));
    }

    async create(payload: CreateDeveloperDTO): Promise<DeveloperDetails> {
        const response = await axios.post(DEVELOPERS_URL, payload);
        const dto: DeveloperDetailsDTO = response.data;
        return new DeveloperDetails(dto);
    }

    async update(id: string, payload: UpdateDeveloperDTO): Promise<DeveloperDetails> {
        const response = await axios.post(DEVELOPER_URL(id), payload);
        const dto: DeveloperDetailsDTO = response.data;
        return new DeveloperDetails(dto);
    }
}