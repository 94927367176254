import _ from "lodash";
import { CalibrationCriteriaDetails, CalibrationCriteriaDetailsDTO, PdfFileInfos, PdfFileInfosDTO, RoughnessCriteria, RoughnessCriteriaDTO, TemperatureCriteria, TemperatureCriteriaDTO, ThicknessCriteria, ThicknessCriteriaDTO, ThresholdCriteria, ThresholdCriteriaDTO, UncertaintyCriteria, UncertaintyCriteriaDTO, WeldingCriteria, WeldingCriteriaDTO } from "@/domain";

export class ProceadureDetails {
    public id: string;
    public name: string;
    public temperatureCriteria: TemperatureCriteria | null;
    public thicknessCriteria: ThicknessCriteria | null;
    public roughnessCriteria: RoughnessCriteria | null;
    public weldingCriteria: WeldingCriteria | null;
    public calibrationCriteria: CalibrationCriteriaDetails | null;
    public uncertaintyCriteria: UncertaintyCriteria | null;
    public thresholdCriteria: ThresholdCriteria | null;
    public pdfFiles: PdfFileInfos[];
    public disabled: boolean;

    constructor(parameters: ProceadureDetailsDTO) {
        this.id = parameters.id;
        this.name = parameters.name;
        this.temperatureCriteria = parameters.temperatureCriteria && new TemperatureCriteria(parameters.temperatureCriteria) || null;
        this.thicknessCriteria = parameters.thicknessCriteria && new ThicknessCriteria(parameters.thicknessCriteria) || null;
        this.roughnessCriteria = parameters.roughnessCriteria && new RoughnessCriteria(parameters.roughnessCriteria) || null;
        this.weldingCriteria = parameters.weldingCriteria && new WeldingCriteria(parameters.weldingCriteria) || null;
        this.calibrationCriteria = parameters.calibrationCriteria && new CalibrationCriteriaDetails(parameters.calibrationCriteria) || null;
        this.uncertaintyCriteria = parameters.uncertaintyCriteria && new UncertaintyCriteria(parameters.uncertaintyCriteria) || null;
        this.thresholdCriteria = parameters.thresholdCriteria && new ThresholdCriteria(parameters.thresholdCriteria) || null;
        this.pdfFiles = _.map(parameters.pdfFiles, f => new PdfFileInfos(f));
        this.disabled = parameters.disabled;
    }
}
export interface ProceadureDetailsDTO {
    id: string;
    name: string;
    temperatureCriteria: TemperatureCriteriaDTO | null;
    thicknessCriteria: ThicknessCriteriaDTO | null;
    roughnessCriteria: RoughnessCriteriaDTO | null;
    weldingCriteria: WeldingCriteriaDTO | null;
    calibrationCriteria: CalibrationCriteriaDetailsDTO | null;
    uncertaintyCriteria: UncertaintyCriteriaDTO | null;
    thresholdCriteria: ThresholdCriteriaDTO | null;
    pdfFiles: PdfFileInfosDTO[];
    disabled: boolean;
}