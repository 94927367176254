import { EquipmentBrandInfos, EquipmentBrandInfosDTO, RoughometerDetails } from "@/domain";

export class RoughometerItem {
    public id: string;
    public serialNumber: string;
    public equipmentType: string;
    public equipmentBrand: EquipmentBrandInfos;
    public disabled: boolean;

    constructor(parameters: RoughometerItemDTO) {
        this.id = parameters.id;
        this.serialNumber = parameters.serialNumber;
        this.equipmentType = parameters.equipmentType;
        this.equipmentBrand = new EquipmentBrandInfos(parameters.equipmentBrand);
        this.disabled = parameters.disabled;
    }

    static fromDetails(roughometer: RoughometerDetails): RoughometerItem {
        return new RoughometerItem({
            id: roughometer.id,
            serialNumber: roughometer.serialNumber,
            equipmentType: roughometer.equipmentType,
            equipmentBrand: roughometer.equipmentBrand,
            disabled: roughometer.disabled
        })
    }
}

export interface RoughometerItemDTO {
    id: string;
    serialNumber: string;
    equipmentType: string;
    equipmentBrand: EquipmentBrandInfosDTO;
    disabled: boolean;
}