import _ from "lodash";
import axios from "axios";

import { buildURL, createProceadureToFormData, updateProceadureToFormData } from "@/tools";
import { PROCEADURE_URL, PROCEADURES_URL } from "@/config/urls";

import { ProceadureDetails, ProceadureDetailsDTO, ProceadureInfos, ProceadureInfosDTO } from "@/domain";
import { CreateProceadureDTO, IProceadureService, ProceadureFilterDTO, UpdateProceadureDTO } from "@/services";

export class ProceadureService implements IProceadureService {
    async get(id: string): Promise<ProceadureDetails> {
        const response = await axios.get(PROCEADURE_URL(id));
        const dto: ProceadureDetailsDTO = response.data;
        return new ProceadureDetails(dto);
    }

    async getMany(filter: ProceadureFilterDTO): Promise<ProceadureInfos[]> {
        const response = await axios.get(buildURL(PROCEADURES_URL, filter));
        const dtos: ProceadureInfosDTO[] = response.data;
        return _.map(dtos, d => new ProceadureInfos(d));
    }

    async create(payload: CreateProceadureDTO): Promise<ProceadureDetails> {
        const formData = createProceadureToFormData(payload);
        const response = await axios.post(PROCEADURES_URL, formData);
        const dto: ProceadureDetailsDTO = response.data;
        return new ProceadureDetails(dto);
    }

    async update(id: string, payload: UpdateProceadureDTO): Promise<ProceadureDetails> {
        const formData = updateProceadureToFormData(payload);
        const response = await axios.post(PROCEADURE_URL(id), formData);
        const dto: ProceadureDetailsDTO = response.data;
        return new ProceadureDetails(dto);
    }

    async remove(id: string): Promise<void> {
        await axios.delete(PROCEADURE_URL(id));
    }
}