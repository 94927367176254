
export class RunParametersInfos {
    // Proceadure
    public proceadureId: string | null;
    public uncertaintyProceadureId: string | null;
    // Equipments
    public thermometerId: string | null;
    public roughometerId: string | null;
    public v1CalibrationBlockId: string | null;
    public v2CalibrationBlockId: string | null;
    public stepCalibrationBlockId: string | null;
    public utGelId: string | null;
    public utProbeId: string | null;
    public utBoardId: string | null;

    constructor(parameters: RunParametersInfosDTO) {
        this.proceadureId = parameters.proceadureId;
        this.uncertaintyProceadureId = parameters.uncertaintyProceadureId;
        this.thermometerId = parameters.thermometerId;
        this.roughometerId = parameters.roughometerId;
        this.v1CalibrationBlockId = parameters.v1CalibrationBlockId;
        this.v2CalibrationBlockId = parameters.v2CalibrationBlockId;
        this.stepCalibrationBlockId = parameters.stepCalibrationBlockId;
        this.utBoardId = parameters.utBoardId;
        this.utGelId = parameters.utGelId;
        this.utProbeId = parameters.utProbeId;
    }
}

export interface RunParametersInfosDTO {
    // Proceadure
    proceadureId: string | null;
    uncertaintyProceadureId: string | null;
    // Equipments
    thermometerId: string | null;
    roughometerId: string | null;
    v1CalibrationBlockId: string | null;
    v2CalibrationBlockId: string | null;
    stepCalibrationBlockId: string | null;
    utGelId: string | null;
    utProbeId: string | null;
    utBoardId: string | null;
}

