import { EquipmentBrandInfos, EquipmentBrandInfosDTO, HygrometerDetails } from "@/domain";

export class HygrometerItem {
    public id: string;
    public serialNumber: string;
    public equipmentType: string;
    public equipmentBrand: EquipmentBrandInfos;
    public pvNumber: string;
    public disabled: boolean;

    constructor(parameters: HygrometerItemDTO) {
        this.id = parameters.id;
        this.serialNumber = parameters.serialNumber;
        this.equipmentType = parameters.equipmentType;
        this.equipmentBrand = new EquipmentBrandInfos(parameters.equipmentBrand);
        this.pvNumber = parameters.pvNumber;
        this.disabled = parameters.disabled;
    }

    static fromDetails(hygrometer: HygrometerDetails): HygrometerItem {
        return new HygrometerItem({
            id: hygrometer.id,
            serialNumber: hygrometer.serialNumber,
            equipmentType: hygrometer.equipmentType,
            equipmentBrand: hygrometer.equipmentBrand,
            pvNumber: hygrometer.pvNumber,
            disabled: hygrometer.disabled
        })
    }
}

export interface HygrometerItemDTO {
    id: string;
    serialNumber: string;
    equipmentType: string;
    equipmentBrand: EquipmentBrandInfosDTO;
    pvNumber: string;
    disabled: boolean;
}