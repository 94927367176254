import axios from "axios";
import _ from "lodash";

import { CreatePowerPlantDTO, IPowerPlantService, PowerPlantFilterDTO, UpdatePowerPlantDTO, UpdateStatusPowerPlantDTO } from "@/services/interfaces";
import { POWER_PLANTS_URL, POWER_PLANT_STATUS_URL, POWER_PLANT_URL } from "@/config/urls";
import { buildURL } from "@/tools";

import { PowerPlantInfos, PowerPlantInfosDTO, } from "prometheus-synced-ui";
import { PowerPlantTree, PowerPlantTreeDTO, PowerPlantDetails, PowerPlantDetailsDTO, } from "@/domain";

export class PowerPlantService implements IPowerPlantService {
    async get(id: string): Promise<PowerPlantDetails> {
        const response = await axios.get(POWER_PLANT_URL(id));
        const dto: PowerPlantDetailsDTO = response.data;
        return new PowerPlantDetails(dto);
    }

    async getMany(filter: PowerPlantFilterDTO): Promise<PowerPlantInfos[]> {
        const response = await axios.get(buildURL(POWER_PLANTS_URL, filter));
        const dtos: PowerPlantInfosDTO[] = response.data;
        return _.map(dtos, d => new PowerPlantInfos(d));
    }

    async create(payload: CreatePowerPlantDTO): Promise<PowerPlantDetails> {
        const response = await axios.post(POWER_PLANTS_URL, payload);
        const dto: PowerPlantDetailsDTO = response.data;
        return new PowerPlantDetails(dto);
    }

    async update(id: string, payload: UpdatePowerPlantDTO): Promise<PowerPlantDetails> {
        const response = await axios.post(POWER_PLANT_URL(id), payload);
        const dto: PowerPlantDetailsDTO = response.data;
        return new PowerPlantDetails(dto);
    }

    async updateStatus(id: string, payload: UpdateStatusPowerPlantDTO): Promise<PowerPlantTree> {
        const response = await axios.post(POWER_PLANT_STATUS_URL(id), payload);
        const dto: PowerPlantTreeDTO = response.data;
        return new PowerPlantTree(dto);
    }
}