import _ from "lodash";
import { NuclearUnitTree, NuclearUnitTreeDTO } from "@/domain";
import { PowerPlantInfos } from "prometheus-synced-ui";

export class PowerPlantTree {
    public id: string;
    public name: string;
    public trigram: string;
    public nuclearUnits: NuclearUnitTree[];
    public disabled: boolean;

    constructor(parameters: PowerPlantTreeDTO) {
        this.id = parameters.id;
        this.name = parameters.name;
        this.trigram = parameters.trigram;
        this.nuclearUnits = _.map(parameters.nuclearUnits, g => new NuclearUnitTree(g));
        this.disabled = parameters.disabled;
    }

    public toInfos(): PowerPlantInfos {
        return new PowerPlantInfos({
            id: this.id,
            name: this.name,
            trigram: this.trigram,
            disabled: this.disabled
        });
    }
}

export interface PowerPlantTreeDTO {
    id: string;
    name: string;
    trigram: string,
    nuclearUnits: NuclearUnitTreeDTO[];
    disabled: boolean;
}