export enum ImportWarningType {
    unknown = 0,
    visitDifference = 1,
    measureValueDifference = 2,
    headerDifference = 3
}

interface ImportWarningTypeDisplay {
    label: string;
}
const warningTypes: Map<ImportWarningType, ImportWarningTypeDisplay> = new Map<ImportWarningType, ImportWarningTypeDisplay>();

warningTypes.set(ImportWarningType.visitDifference, {
    label: "Il existe un historique avec la même date, les mêmes mesures mais avec un numéro de visite différent",
});

warningTypes.set(ImportWarningType.measureValueDifference, {
    label: "Il existe un historique avec le même numéro de visite, la même date mais avec des mesures différentes",
});

warningTypes.set(ImportWarningType.headerDifference, {
    label: "Il existe un historique avec le même numéro de visite, la même date, les mêmes mesures mais avec des entêtes de ligne/colonne différents",
});

export { warningTypes as IMPORT_WARNING_TYPES_DISPLAY };
