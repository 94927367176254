export class OffTableMeasureInfos {
    public id: string;
    public measureId: string;
    public value: number;
    public positionX: number;
    public positionY: number;

    constructor(parameters: OffTableMeasureInfosDTO) {
        this.id = parameters.id;
        this.measureId = parameters.measureId;
        this.value = parameters.value;
        this.positionX = parameters.positionX;
        this.positionY = parameters.positionY;
    }
}

export interface OffTableMeasureInfosDTO {
    id: string;
    measureId: string;
    value: number;
    positionX: number;
    positionY: number;
}