import { MaterialShadeInfos, MaterialShadeInfosDTO } from "@/domain";

export class CalibrationCriteriaDetails {
    public materialShade: MaterialShadeInfos;
    public celerityValue: number;
    public celerityRange: number;
    public minimumCelerity: number;
    public maximumCelerity: number;

    constructor(parameters: CalibrationCriteriaDetailsDTO) {
        this.materialShade = new MaterialShadeInfos(parameters.materialShade);
        this.celerityValue = parameters.celerityValue;
        this.celerityRange = parameters.celerityRange;
        this.minimumCelerity = parameters.minimumCelerity;
        this.maximumCelerity = parameters.maximumCelerity;
    }
}

export interface CalibrationCriteriaDetailsDTO {
    materialShade: MaterialShadeInfosDTO;
    celerityValue: number;
    celerityRange: number;
    minimumCelerity: number;
    maximumCelerity: number;
}
