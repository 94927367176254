export enum ThicknessType {
    unknown = 0,
    intradosNominal = 1,
    intradosMinManufactured = 2,
    intradosCharacterisation = 3,
    intradosCalculation = 4,
    extradosNominal = 5,
    extradosMinManufactured = 6,
    extradosCharacterisation = 7,
    extradosCalculation = 8
}

interface ThicknessTypeDisplay {
    label: string;
}

const types: Map<ThicknessType, ThicknessTypeDisplay> = new Map<ThicknessType, ThicknessTypeDisplay>();

types.set(ThicknessType.intradosNominal, {
    label: "Nominal (Intrados)"
});

types.set(ThicknessType.intradosMinManufactured, {
    label: "Mini de fabrication (Intrados)"
});

types.set(ThicknessType.intradosCharacterisation, {
    label: "Caractérisation (Intrados)"
});

types.set(ThicknessType.intradosCalculation, {
    label: "Calcul (Intrados)"
});

types.set(ThicknessType.extradosNominal, {
    label: "Nominal (Extrados)"
});

types.set(ThicknessType.extradosMinManufactured, {
    label: "Mini de fabrication (Extrados)"
});

types.set(ThicknessType.extradosCharacterisation, {
    label: "Caractérisation (Extrados)"
});

types.set(ThicknessType.extradosCalculation, {
    label: "Calcul (Extrados)"
});

export {types as THICKNESS_TYPES_DISPLAY};
