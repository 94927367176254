import axios from "axios";
import _ from "lodash";

import { MOCK_IMPORT_EXCEL_EXAM_REPORT_URL } from "@/config/urls";
import { CreateExamReportDTO, IMockExcelExamReportService } from "@/services/interfaces";

import { ImportWarning, ImportWarningDTO } from "@/domain";

export class MockExcelExamReportService implements IMockExcelExamReportService {

    async mockImport(payload: CreateExamReportDTO): Promise<ImportWarning[]> {
        const response = await axios.post(MOCK_IMPORT_EXCEL_EXAM_REPORT_URL, payload);
        const dtos: ImportWarningDTO[] = response.data;
        return _.map(dtos, d => new ImportWarning(d));
    }
}