import { MaterialShadeInfos, MaterialShadeInfosDTO } from "@/domain";

export class V1CalibrationBlockDetails {
    public id: string;
    public serialNumber: string;
    public minDimension: number;
    public maxDimension: number;
    public materialShade: MaterialShadeInfos;
    public celerity: number;
    public pvNumber: string;
    public disabled: boolean;

    constructor(parameters: V1CalibrationBlockDetailsDTO) {
        this.id = parameters.id;
        this.serialNumber = parameters.serialNumber;
        this.minDimension = parameters.minDimension;
        this.maxDimension = parameters.maxDimension;
        this.materialShade = new MaterialShadeInfos(parameters.materialShade);
        this.celerity = parameters.celerity;
        this.pvNumber = parameters.pvNumber;
        this.disabled = parameters.disabled;
    }
}

export interface V1CalibrationBlockDetailsDTO {
    id: string;
    serialNumber: string;
    minDimension: number;
    maxDimension: number;
    materialShade: MaterialShadeInfosDTO;
    celerity: number;
    pvNumber: string;
    disabled: boolean;
}