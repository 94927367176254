export class SyncGatewayCredentialsToken {
    public id: string;
    public token: string;

    constructor(parameters: SyncGatewayCredentialsTokenDTO) {
        this.id = parameters.id;
        this.token = parameters.token;
    }
}

export interface SyncGatewayCredentialsTokenDTO {
    id: string;
    token: string;
}