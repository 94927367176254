import { MaterialShadeInfos, MaterialShadeInfosDTO } from "@/domain";

export class V2CalibrationBlockDetails {
    public id: string;
    public serialNumber: string;
    public dimensionValue: number;
    public materialShade: MaterialShadeInfos;
    public celerity: number;
    public pvNumber: string;
    public disabled: boolean;

    constructor(parameters: V2CalibrationBlockDetailsDTO) {
        this.id = parameters.id;
        this.serialNumber = parameters.serialNumber;
        this.dimensionValue = parameters.dimensionValue;
        this.materialShade = new MaterialShadeInfos(parameters.materialShade);
        this.celerity = parameters.celerity;
        this.pvNumber = parameters.pvNumber;
        this.disabled = parameters.disabled;
    }
}

export interface V2CalibrationBlockDetailsDTO {
    id: string;
    serialNumber: string;
    dimensionValue: number;
    materialShade: MaterialShadeInfosDTO;
    celerity: number;
    pvNumber: string;
    disabled: boolean;
}